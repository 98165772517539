<template>
    <div class="header-content" :class="{
        flex: !$store.getters.is_mobile
    }">
        <header-home-images
                v-if="$store.getters.is_mobile"
                :header1="header1"
                :header2="header2"
                :is_extra_absolute="true"
        />
        <div class="content-text">
            <h1 class="f-letter"
                :class="{
                'f-s-title': !$store.getters.is_mobile,
                'f-s-subtitle': $store.getters.is_mobile,
                'f-weight-medium': $store.getters.is_mobile
                }"
                v-text="get_i18n($store.getters.i_home_header, 'start_accepting_crypto_payments_today')"></h1>
            <p class="f-s-subtitle"
               :class="{
                'f-s-ms': $store.getters.is_mobile,
                'f-s-subtitle': !$store.getters.is_mobile
               }"
               v-text="get_i18n($store.getters.i_home_header, 'accept_cryptocurrency_payments')"></p>
            <div class="wrap-button flex">
                <mn-button
                        :width="$store.getters.is_mobile ? '172px' : '256px'"
                        padding="10px 16px"
                        to="/auth/"
                        target="new"
                        :text="get_i18n($store.getters.i_button, 'get_started_now')"
                />
            </div>
            <p class="support-text f-s-ms"
               :class="{
                'text-al-center': $store.getters.is_mobile,
                'f-s-ms': !$store.getters.is_mobile,
                'f-ss-ms': $store.getters.is_mobile
               }"
               v-text="get_i18n($store.getters.i_home_header, 'we_support_payments_in_six_coins')"></p>
            <div class="flex support-coins" :class="{
                'jc-center': $store.getters.is_mobile
            }">
                <img v-for="coin in coins" :src="coin" width="32" height="32" alt="">
            </div>
        </div>
        <header-home-images
                :header1="$store.getters.is_mobile ? undefined : header1"
                :header2="$store.getters.is_mobile ? undefined : header2"
                :header3="header3"
                :header4="header4"
                :is_extra_absolute="!$store.getters.is_mobile"
        />
    </div>
</template>

<script>
import utils from "module/utils";
import MnButton from "@/UI/mnButton.vue";
import RenderSvg from "@/UI/svg/renderSvg.vue";
import env from "module/env";
import HeaderHomeImages from "mnhome/UI/headerHomeImages.vue";

export default {
    name: "headerHome",
    components: {HeaderHomeImages, RenderSvg, MnButton},
    methods: {
        get_i18n: utils.get_i18n
    },
    data() {
        return {
            header1: "",
            header2: "",
            header3: "",
            header4: "",
            coins: []
        }
    },
    mounted() {
        this.header1 = require('assets/home/img/home-header.webp')
        this.header2 = require("assets/home/img/home-page-1.webp")
        this.header3 = require('assets/home/img/home-page-2.jpg')
        this.header4 = require('assets/home/img/home-page-3.webp')
        this.coins.push(
            require('assets/home/img/coins/bnb.png'),
            require('assets/home/img/coins/usdt.png'),
            require('assets/home/img/coins/btc.png'),
            require('assets/home/img/coins/eth.png'),
            require('assets/home/img/coins/matic.png'),
            require('assets/home/img/coins/usdc.png'),
        )
    }
}
</script>

<style scoped lang="scss">
.header-content {
  margin-top: 25px;
  padding-bottom: 152px;
  @media screen and (max-width: 520px) {
    padding-bottom: 55px;
  }
}

.content-text {
  padding-top: 102px;
  margin-right: 29px;
  min-width: 536px;
  max-width: 536px;
  @media screen and (max-width: 520px) {
    padding-top: 0;
    margin-top: 56px;
    margin-right: 0;
    min-width: auto;
    width: 100%;
  }
}

h1 {
  width: 80%;
  margin-bottom: 24px;
  @media screen and (max-width: 520px) {
    margin-bottom: 16px;
  }
}

.wrap-button {
  margin-bottom: 64px;
  margin-top: 24px;
  @media screen and (max-width: 520px) {
    margin-top: 16px;
  }
}

.support {
  &-text {
    width: 392px;
    @media screen and (max-width: 520px) {
      width: 100%;
      line-height: 18px;
    }
  }

  &-coins {
    margin-top: 24px;

    img {
      margin-right: 16px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

</style>