import apiUrl from "module/api/api-url";
import {connect, processing_connect, task_connecting} from "module/api/controllers/main";

export default {
    async total_balance() {
        return await connect(apiUrl.admin.total_balance, {}, true).post_json()
    },
    async total_balance_user_by_id(user_id) {
        return await connect(apiUrl.admin.total_balance_user_by_id, {user_id}, true).post_json()
    },
    async user_show_withdraw(seller_id, offset, limit) {
        return await connect(apiUrl.admin.user_show_withdraw, {seller_id, offset, limit}, true).post_json()
    },
    async user_bio_get(user_id) {
        return await connect(apiUrl.admin.user_bio_get, {user_id}, true).post_json()
    },
    async user_fee_Set(user_id, fee) {
        return await connect(apiUrl.admin.user_fee_set, {user_id, fee}, true).post_json()
    },
    async invest_beneficiaries() {
      return await connect(apiUrl.admin.invest_beneficiaries, {}, true).post_json()
    },
    async invest_list_percent() {
      return await connect(apiUrl.admin.invest_list_percent, {}, true).post_json()
    },
    async invest_set_percent(percent, period) {
        return await connect(apiUrl.admin.invest_set_percent, {year_month: period, percent}, true).post_json()
    },
    async dashboard(type) {
        return await connect(apiUrl.admin.dashboard, {dashboard:type}, true).post_json()
    },
    async invest_pay_dividends(token_id) {
        return await connect(apiUrl.admin.invest_pay_dividends, {token_id}, true).post_json()
    },
    async user_find_id_by_email(email) {
        if (!email) {
            let id = task_connecting['user_find']
            if (id) clearTimeout(id)
            return {data: []}
        }
        return processing_connect('user_find', apiUrl.admin.user_find, {email}, true, false)
    }
}