<template>
  <div @click="show=!show" :class="{
          'active': show
        }" class="cursorPointer transition-easy_in f-inr f-s-ms select_field flex jc-sb al-center">
    <div class="selected flex al-center" v-if="modelValue[get_text]">
      <div class="icon_select">
        <img width="24" v-if="is_img" :src="modelValue.icon" alt="">
        <div v-else v-html="modelValue.icon"></div>
      </div>
      <div class="text f-hc f-s-ms" v-text="modelValue[get_text]"></div>
    </div>
    <div class="selected" v-else v-text="placeholder"></div>
    <mn-drop-down>
      <div @click.stop v-if="!is_modal" v-show="show" class="wrap_select_items">
        <div class="select_items">
          <div
              class="select_item flex al-center"
              v-for="select_item in select_items"
              :key="select_item.value"
              @click="$emit('update:modelValue', select_item);$emit('change', select_item);show=false"
          >
            <div class="icon_select">
              <img
                      width="24"
                      height="24"
                      v-if="is_img&&!select_item.is_svg"
                   :src="select_item.icon"
                   alt="">
              <div v-else v-html="select_item.icon"></div>
            </div>
            <div class="text f-hc f-s-ms" v-text="select_item[get_text]"></div>
          </div>
        </div>
      </div>
      <pop-up-select v-else @click.stop :get_text="get_text" v-model="show" :items="select_items"/>
    </mn-drop-down>
    <div class="icon" v-html="icon" v-if="select_items.length>0"></div>
  </div>
</template>

<script>
import PopUpSelect from "@/commponents/popUpSelect";
import env from "module/env";
import MnDropDown from "@/commponents/effects/mnDropDown.vue";

export default {
  name: "iconSelectField",
  components: {MnDropDown, PopUpSelect},
  props: {
    select_items: Array,
    modelValue: Object,
    placeholder: String,
    get_key: {
      default: 'id',
      type: String
    },
    get_text: {
      default: 'text',
      type: String
    },
    is_modal: {
      default: false,
      type: Boolean
    },
    is_img: {
      default: true,
      type: Boolean
    }
  },
  data() {
    return {
      show: false,
      icon: env.icons.arrow
    }
  }

}
</script>

<style scoped lang="scss">
.select_field {
  border: 2px solid #F1F1F1;
  border-radius: 8px;
  padding: 11px 8px;
  outline: none;
  position: relative;
  z-index: 1;

  &.active {
    border-radius: 8px 8px 0 0;
  }

  .wrap_select_items {
    height: 0;
    max-height: 20vh;
    position: absolute;
    left: -2px;
    right: -2px;
    top: 55px;
  }

  .select_items {
    transition: 0.5s;
    background-color: white;
    border-radius:  0 0 8px 8px;
    border: solid #f1f1f1;
    border-width: 0 2px 2px 2px;
  }

  .select_item {
    padding: 14px 8px;

    .icon_select {
      line-height: 0;
      margin-right: 8px;
    }
  }

  .selected {
    .icon_select {
      line-height: 0;
      margin-right: 8px;
    }
  }

  .select_item {
    transition: 0.5s;

    &:hover, &:active {
      background-color: #F1F1F1;
    }
  }


  .icon {
    transform: rotate(-90deg);
  }
}
</style>