import server from "./api/server";
import ClipboardJS from "clipboard";
import config from "module/config";
import PostgresInterval from "postgres-interval";

function gallery_upload(name, file) {
    let form = new FormData()
    form.set("image-upload", file ? file : '')
    form.set('category', name)
    return form
}

let cache = {
    calc_ficr: {}
}

async function convert_calc(fr, to, count = 1) {
    let a = await server.blockchain.exchange_rates(fr, to)
    if (a.status >= 400) return 0
    set_cache();
    return count * a.price


    function set_cache() {
        cache.calc_ficr[fr + to] = {fr, to, price: a.price};
    }
}

export default {
    random(max) {
        return Math.floor(Math.random() * max)
    },
    async sleep(ms) {
        return new Promise(r => setTimeout(() => r(null), ms))
    },
    async upload_img(file) {
        let form = new FormData()
        form.set("image-upload", file, encodeURIComponent(file.name))
        return server.seller.upload_file(form)
    },
    async create_album(name) {
        let form = gallery_upload(name)
        return await server.seller.image_gallery(form)
    },
    async get_galleria(seller_id) {
        return await server.public.get_image_gallery(seller_id, "")
    },
    async get_album(seller_id, name) {
        return await server.seller.get_image_gallery(seller_id, name)
    },
    async set_album(name, file) {
        let form = gallery_upload(name, file)
        return await server.seller.image_gallery(form)
    },
    get_chains() {
        return server.blockchain.chain_list()
    },
    set_bio(params) {
        return server.user.set_bio(params)
    },
    copy(text) {
        return ClipboardJS.copy(text)
    },
    share(title, link, description) {
        let data = {
            title, url: link, text: description
        }
        let share = navigator.canShare
        if (!share || !navigator.canShare(data)) {
            throw "Not supported"
        }
        return navigator.share(data)
    },
    user_menu_target(url, target) {
        const domain = x => document.domain !== 'localhost' ? config.front[x] : document.location.origin
        let origin = domain(target)

        return origin + url
    },
    async get_calc_currency(context, from_cur, to_currency) {
        let c;
        if (cache.calc_ficr && cache.calc_ficr[from_cur + to_currency]) {
            c = cache.calc_ficr[from_cur + to_currency];
            c = c.price * context.value
        }
        const conv_value = parseFloat(context.value);
        if (!c) {
            c = await convert_calc(from_cur, to_currency, conv_value);
        }
        return {c, conv_value};
    },
    async get_calc_rate(to, from, value) {
        if (to.toLowerCase() === from.toLowerCase()) return value
        let d = await server.blockchain.rates_calculator(to, from, value)
        return d.to_amount ?? 0
    },
    coming_soon() {
        alert('Coming Soon!')
    },
    parseJSON(obj) {
        try {
            return JSON.parse(obj)
        } catch (e) {
            return obj
        }
    },
    get_i18n(fr, key) {
        if (!fr) return key
        return fr[key] ?? key.replaceAll('_', ' ')
    },
    replacement(text, keys, prefix = ['{{', '}}']) {
        for (let i in keys) {
            if (keys[i] === undefined || keys[i] === null) keys[i] = ''
            text = text.replaceAll(`${prefix[0]}${i}${prefix[1]}`, keys[i])
        }
        return text
    },
    getColumnCount(is_mobile, screenWidth) {
        return is_mobile || screenWidth < 680 ? 2 : screenWidth < 840 ? 3 : screenWidth < 1180 ? 4 : 5
    },
    getColumnWidth(count, margin, is_last) {
        return (100 - (is_last ? count : count - 1) * margin) / count
    },
    getImage(src) {
        return require("assets/" + src)
    },
    columnList(list, count) {
        let n = []
        for (let i = 0; i < list.length; i++) {
            let idx = Math.floor(i / count)
            if (n.length === 0 || !n[idx]) n.push([])
            n[idx].push(list[i])
        }
        return n
    },
    blockchainSelect(currency, chain_id, blc) {
        if (!blc) return;
        let chd = blc[chain_id]
        if (!chd) return this.blockchainSelect(currency, Object.keys(blc)[0], blc)
        blc.select = {...chd, chain_id, currency}
    },
    beautiful_number(num) {
        let [$int, $float] = num.toString().split(".")
        $int = $int.split("").reverse()
        let n = ''
        for (let i = 0; i < $int.length; i++) {
            if (!!i && i % 3 === 0) {
                n += ' '
            }
            n += $int[i]
        }
        $float = $float ? '.' + $float : '.00'
        return `${n.split("").reverse().join("")}${$float}`
    },
    str_to_list(text) {
        if (typeof text === 'string') return [text]
        return text
    },
    intersectionObserver(el, event_callback, rootMargin = "100px") {
        let callback = function (entries) {
            if (event_callback) event_callback(entries[0].isIntersecting)
        }
        let config = {
            threshold: 1,
            rootMargin
        }
        let observer = new IntersectionObserver(callback, config)
        observer.observe(el); //Start observer
    },
    getNoun(number, one, two, five) {
        let n = Math.abs(number);
        n %= 100;
        if (n >= 5 && n <= 20) {
            return five;
        }
        n %= 10;
        if (n === 1) {
            return one;
        }
        if (n >= 2 && n <= 4) {
            return two;
        }
        return five;
    },
    getStringFromPgInterval(period, words = {
        year: "year",
        month: "month",
        day: "day",
        hour: "hour",
        minute: "minute",
        second: "second",
        millisecond: "millisecond"
    }, with_num = false) {
        let {months, days, hours, milliseconds, minutes, seconds, years} = period
        let check_valid = x => x !== undefined && x !== 0 && x !== null
        let get_text = (x, y) => check_valid(x) ? (x > 1 && !with_num ? '' + x + ' ' : '') + y + ' ' : ''

        return get_text(years, words.year) +
            get_text(months, words.month) +
            get_text(days, words.day) +
            get_text(hours, words.hour) +
            get_text(minutes, words.minute) +
            get_text(seconds, words.second ?? 'second') +
            get_text(milliseconds, words.millisecond ?? 'millisecond')
    },
    getIntervalPG(nm, tp) {
        // nm == number, tp == interval type
        let v = parseInt(nm)
        if (isNaN(v) && v.length > 0) {
            throw "NO_NUMBER"
        } else if (isNaN(v)) return;
        let pg = PostgresInterval("")
        pg[tp] = v
        return pg.toPostgres();
    },
    async funcToAsync(callback, ...args) {
        return callback(...args)
    },
    intervalToMilliseconds(interval) {
        const units = ['years', 'months', 'weeks', 'days', 'hours', 'minutes', 'seconds', 'milliseconds'];

        return units.reduce((milliseconds, unit) => {
            if (typeof interval[unit] !== "number") return 0
            let mls = {
                years: 31536000000,
                months: 2592000000,
                weeks: 604800000,
                days: 86400000,
                hours: 3600000,
                minutes: 60000,
                seconds: 1000,
                milliseconds: 1
            }
            return milliseconds + interval[unit] * mls[unit]

        }, 0);
    },
    millisecondsToInterval(interval, milliseconds) {
        const years = Math.floor(milliseconds / (1000 * 60 * 60 * 24 * 365));
        const months = Math.floor(milliseconds / (1000 * 60 * 60 * 24 * 30)) % 12;
        const days = Math.floor(milliseconds / (1000 * 60 * 60 * 24)) % 30;
        const hours = Math.floor(milliseconds / (1000 * 60 * 60)) % 24;
        const minutes = Math.floor(milliseconds / (1000 * 60)) % 60;
        const seconds = Math.floor(milliseconds / 1000) % 60;
        let intr = {
            years, months,
            days: days,
            hours, minutes, seconds
        }
        console.log(intr, milliseconds)
        for (let i in intr) {
            interval[i] = intr[i]
        }
    },
    get_max_float_by_currency(currency) {
        switch(currency) {
            case 'USD':
            case 'EUR':
            case 'RUB':
            case 'USDT':
            case 'USDC':
            case 'BUSD':
                return 2; // "cents"
            default:
                return 6;
        }
    }
}