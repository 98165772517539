<template>
  <header class="bg-white" :style="{
    top: config.debug ? '24px' : 0
  }">
    <nav class="container navbar-customers flex al-center jc-sb">
      <div class="switch flex al-center">
        <div v-if="$store.state.authenticated" tabindex="0" class="switch_menu">
          <div @click="is_burger=!is_burger"
               class="svg cursorPointer bg-light_grey switch_menu-button"
               v-html="get_burger_icon"></div>
          <mn-drop-down>
            <div v-if="is_burger" class="switch_menu-list">
              <div class="menu flex flex-column jc-sb">
                <div>
                  <mn-profile v-if="$store.state.authenticated"/>
                  <div :style="{
                      marginTop: !$store.state.authenticated ? '-32px' : ''
                      }" class="menu-list"
                  >
                    <mn-menu-list/>
                  </div>
                </div>
                <div @click="$store.state.authenticated ? logout() : $router.push('/auth/')"
                     class="f-s-ms flex f-weight-medium profile-action cursorPointer text-underline"
                >
                  <render-svg :class="{
                    logout: $store.state.authenticated
                  }" :svg="$store.state.authenticated ? ico_logout:ico_login"/>
                  <p v-text="$store.state.authenticated ? get_i18n($store.getters.i_menu, 'log_out') : get_i18n($store.getters.i_menu, 'auth')"></p>
                </div>
              </div>
            </div>
          </mn-drop-down>
        </div>
        <render-svg
            class="cursorPointer svg logo"
            :svg="logo"
            width="169px"
            height="32px"
            @click="target_home"
        />
      </div>
      <div class="navigation">
          <a class="f-s-ms f-weight-medium" v-for="i in links" :href="i.link" v-text="get_i18n($store.getters.i_menu, i.text)"></a>
      </div>
      <div class="action_profile flex al-center">
        <select-field
            get_text="short"
            :is_arrow="false"
            v-model="language"
            :select_items="languages"
            :is_modal="false"
            class="horizontally text-al-center cursorPointer color-grey f-weight-medium f-s-ms f-inr"
            @change="changeLang('error')"
        />
        <p
            :class="{
              'color-black_grey': $route.path === '/auth/',
              'text-underline': $route.path !== '/auth/'
            }"
            class="f-s-ms cursorPointer f-weight-medium f-letter color-black auth_button"
            @click="target_home('/auth/')"
            v-if="!$store.state.authenticated"
            v-text="get_i18n($store.getters.i_menu, 'auth')"
        ></p>
      </div>
    </nav>
    <div :class="{active: is_burger}" class="progress-border"></div>
  </header>
</template>

<script>
import utils from "module/utils";
import env from "module/env";
import RenderSvg from "@/UI/svg/renderSvg.vue";
import mnButton from "@/UI/mnButton.vue";
import mnProfile from "@/commponents/mnProfile.vue";
import mnMenuList from "@/commponents/mnMenuList.vue";
import user from "module/compositionAPI/user";
import IconSelectField from "@/UI/iconSelectField.vue";
import MnDropDown from "@/commponents/effects/mnDropDown.vue";
import SelectField from "@/UI/selectField.vue";
import User from "module/user";
import config from "module/config";
import MnMenuItem from "@/commponents/mnMenuItem.vue";

export default {
  name: "desktopMenu",
  components: {MnMenuItem, SelectField, MnDropDown, IconSelectField, RenderSvg, mnButton, mnProfile, mnMenuList},
  props: {
      links: {
          type: Array,
          default: []
      },
      target: {
          default: 'push',
          type: String
      }
  },
  data() {
    return {
      is_burger: false,
      exit_icon: env.icons.exit,
      logo: env.icons.logo_monets_black,
      menu_burger: env.icons.menu_burger,
      languages: user.languages,
      language: {},
      ico_logout: env.icons.logout,
      ico_login: env.icons.profile
    }
  },
  computed: {
    config() {
      return config
    },
    get_burger_icon() {
      return !this.is_burger ? this.menu_burger : this.exit_icon
    }
  },
  mounted() {
    this.setLang()
  },
  methods: {
    get_i18n: utils.get_i18n,
    setLang: user.setLang,
    changeLang: user.changeLang,
    logout() {
      console.log('INIT logout')
      let u = User.load_data()
      u && u.logout()
      window.open('/', "_self")
    },
    target_home(url) {
        if (typeof url!=='string') url = undefined
        if (this.target === 'new' && url) return window.open(url, "_self")
        if (this.target === 'push' && url) return this.$router.push(url)
        if (!this.target && url) return  window.open(url)
        window.open('/', this.$route.path === '/' || this.$route.path === '/auth/'  ? '_self' : '_blank')
    }
  },
  watch: {
    "$store.state.lang"(v) {
      this.setLang()
    }
  }
}
</script>

<style scoped lang="scss">
header {
  position: fixed;
  z-index: 100;
  left: 0;
  right: 0;
}

.auth_button {
  margin-left: 16px;
}

.navbar-customers {
  z-index: 9999;
  position: relative;
  border-radius: 16px;
  padding: 12px;
  @media screen and (max-width: 1080px) and (min-width: 520px) {
    margin: 0 20px;
  }

  .switch {
    &_menu {
      position: relative;
      z-index: 2;

      &-button {
        padding: 8px;
        border-radius: 8px;
        margin-right: 16px;
      }

      &-list {
        height: 0;
        position: absolute;
        top: 52px;
      }
    }
  }
  .navigation {
    a {
      transition: 0.5s ease-in;
      &:hover {
        color: #017AFF;
      }
    }
  }
}

.menu {
  width: 264px;
  padding: 34px 8px;
  background: #fff;
  overflow: auto;
  max-height: 70vh;
  border-width: 0 1px 1px 1px;
  border-style: solid;
  border-color: #8B90A3;
  border-radius: 0 0 16px 16px;

  .profile-action {
    margin-top: 12px;
    padding-left: 13px;

    p {
      margin-left: 8px;
    }

    .logout {
      transform: rotate(180deg);

      &:hover {
        color: #b7081a;
      }
    }

    &.login {
      &:hover {
        color: #00a3ff;
      }
    }
  }
}
</style>