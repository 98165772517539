<template>
    <div class="inline-info-card" :class="{
        'gradient': isGradient,
        'bg-light_grey': !isGradient
    }">
        <div :class="{
            'gradient-border': isGradient
        }">
            <p v-text="isI18n ? get_i18n($store.getters[i18nKey], title) : title"
               class="f-s-subtitle f-weight-medium"></p>
            <div class="info-card-text f-s-ms">
                <p v-for="txt in texts" v-text="isI18n ? get_i18n($store.getters[i18nKey], txt) : txt"></p>
            </div>
            <p v-if="help" v-text="isI18n ? get_i18n($store.getters[i18nKey], help) : help"
               class="help f-s-ms"></p>
            <lazy-image
                    :src="image"
                    v-if="image"
                    type="iob"
                    :width="imageWidth"
                    :height="imageHeight"
                    :show-load="true"
            />
        </div>
    </div>
</template>

<script>
import utils from "module/utils";
import LazyImage from "mnhome/UI/lazyImage.vue";

export default {
    components: {LazyImage},
    props: {
        title: String,
        text: [String, Array],
        help: String,
        image: String,
        isGradient: Boolean,
        isI18n: Boolean,
        i18nKey: {
            default: 'i-inline-info-card',
            type: String
        },
        imageHeight: String,
        imageWidth: String
    },
    name: "inlineInfoCard",
    methods: {
        get_i18n: utils.get_i18n
    },
    computed: {
        texts() {
            return utils.str_to_list(this.text)
        }
    },
}
</script>

<style scoped lang="scss">
.inline-info-card {
  padding: 16px;
  border-radius: 24px;
}

.info-card-text {
  margin: 8px 0;
  line-height: 20px;

  p {
    margin-bottom: 8px;

    &:last-child {
      margin: 0;
    }
  }
}

.help {
  font-style: italic;
  margin-bottom: 16px;
  color: #6D7885;
  line-height: 20px;
}

.image {
  img {
    width: 100%;
    height: auto;
  }
}

.gradient {
  overflow: hidden;
  padding: 2px;
  background: linear-gradient(90deg, #0085FF 28.46%, #00FFB2 100.09%),
  linear-gradient(90deg, rgba(0, 133, 255, 0.05) 28.46%, rgba(0, 255, 178, 0.05) 100.09%);

  &-border {
    border-radius: 24px;
    padding: 14px;
    background: #fff linear-gradient(90deg, rgba(0, 133, 255, 0.05) 28.46%, rgba(0, 255, 178, 0.05) 100.09%);
  }
}
</style>