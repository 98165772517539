<template>
  <transition
      name="menu-toggle"
      @enter="openMenu"
      @leave="closeMenu"
  >
      <slot></slot>
  </transition>
</template>

<script>
import utils from "module/utils";

export default {
  name: "mnDropDown",
  methods: {
    async openMenu(el, done) {
      if (!el.classList.contains("transition-easy_in")) el.classList.add("transition-easy_in")
      el.style.overflow = 'hidden'
      el.style.height = el.scrollHeight + 'px'
      await utils.sleep(800)
      el.style.overflow = 'auto'
      done()
    },
    async closeMenu(el, done) {
      el.style.overflow = 'hidden'
      el.style.height = '0'
      await utils.sleep(800)
      if (el.classList.contains("transition-easy_in")) el.classList.remove("transition-easy_in")
      done()
    },
  }
}
</script>

<style scoped>

</style>