import config from "module/config";

let {server} = config
let base = `${server}/blockchain`

export default {
    chain_list: `${base}/networks-list`,
    checkout: `${base}/checkout`,
    checkoutV2: `${base}/v2/checkout`,
    exchange_rates: (fr, to) => `${base}/exchange-rates/${to}/${fr}`,
    nft_owner: token_id => `${base}/nft-owner-of/${token_id}`,
    show_checkout: checkout_id => `${base}/checkout-show/${checkout_id}`,
    rates_calculator: (to, from, amount) => `${base}/exchange-rates-calculator/${to}/${from}/${amount}`,
    subscription_pay: x => `${base}/subscription-pay/${x}`,
    subscription: `${base}/subscription`
}