<template>
  <div class="back flex al-center" @click.prevent="to ? $router.push(to) : $router.back()">
    <div class="icon" v-html="icon"></div>
    <p :class="[`f-${fontSize}`, `f-${fontFamily}`]" v-text="title"></p>
  </div>
</template>

<script>
export default {
  name: "mnBack",
  props: {
    icon: String,
    title: String,
    to: String,
    fontSize: {
      type: String,
      default: 's-ms'
    },
    fontFamily: {
      type: String,
      default: 'hc'
    }
  }
}
</script>

<style scoped lang="scss">
.back {
  cursor: pointer;
}
.icon {
  line-height: 0;
}
</style>