<template>
    <div class="content-img">
        <lazy-image
                v-if="header1"
                :width="$store.getters.is_mobile ? '100%': '732px'"
                :height="$store.getters.is_mobile ? 'auto': '612px'"
                :src="header1"
                class="main-img"
        />
        <div class="extra-img" :style="{
            position: is_extra_absolute ? 'absolute' : ''
        }">
            <div v-if="header2" class="phone flex jc-center">
                <lazy-image
                        :src="header2"
                        :width="$store.getters.is_mobile ? 'auto' : '256px'"
                        :height="$store.getters.is_mobile ? '100%' : '554px'"
                />
                <div class="back_radial"></div>
            </div>
            <div v-if="header3" class="history flex jc-center">
                <lazy-image
                        :width="$store.getters.is_mobile ? '100%':'364px'"
                        :height="$store.getters.is_mobile ? 'auto':'52px'"
                        :src="header3"
                />
                <div class="back_radial"></div>
            </div>
            <div v-if="header4" class="balance flex jc-center">
                <lazy-image
                        :width="$store.getters.is_mobile ? '100%': '364px'"
                        :height="$store.getters.is_mobile ? 'auto':'132px'"
                        :src="header4"
                />
                <div class="back_radial"></div>
            </div>
        </div>
    </div>
</template>

<script>
import LazyImage from "mnhome/UI/lazyImage.vue";

export default {
    name: "headerHomeImages",
    components: {LazyImage},
    props: {
        header1: String,
        header2: String,
        header3: String,
        header4: String,
        is_extra_absolute: Boolean
    }
}
</script>

<style scoped lang="scss">
.content-img {
  position: relative;

  .main-img {
    @media screen and (max-width: 520px) {
      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .extra-img {
    left: 0;
    right: 0;
    top: 223px;
    bottom: 0;
    @media screen and (max-width: 520px) {
      top: 30px;
      left: auto;
      right: 18px;
      bottom: -10px;
    }

    .back_radial {
      position: absolute;
    }

    .phone {
      @media screen and (max-width: 520px) {
        height: 100%;
      }

      .back_radial {
        z-index: 1;
        width: 531px;
        height: 531px;
        @media screen and (max-width: 520px) {
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          width: auto;
          height: auto;
        }
      }

      .image {
        z-index: 2;
        border-radius: 20px;
        @media screen and (max-width: 520px) {
          width: auto;
          height: 100%;
          border-radius: 12px;
        }
      }
    }

    .history {
      margin-top: -50px;

      .image {
        border-radius: 12px;
        z-index: 3;
      }

      .back_radial {
        width: 332px;
        height: 52px;
        z-index: 2;
      }

      @media screen and (max-width: 520px) {
        margin-top: 56px;
        .image {
          width: 100%;
          height: auto;
        }
        .back_radial {
          display: none;
        }
      }
    }

    .balance {
      margin-top: 32px;

      .image {
        border-radius: 12px;
        z-index: 3;
      }

      .back_radial {
        right: 155px;
        width: 332px;
        height: 132px;
        z-index: 2;
      }

      @media screen and (max-width: 520px) {
        margin-top: 16px;
        .image {
          width: 100%;
          height: auto;
        }
        .back_radial {
          left: 0;
          right: 0;
          width: auto;
          top: 0;
          bottom: 0;
          height: auto;
        }
      }
    }
  }
}
</style>