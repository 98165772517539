import config from "module/config";

let {server} = config
let base = `${server}/user`


export default {
    login: `${base}/login-email`,
    register: `${base}/register-email`,
    get_shops: `${base}/marketplace-shops-list`,
    get_shop_items: `${base}/marketplace-items-list`,
    get_bio: `${base}/bio-get`,
    set_bio: `${base}/bio-set`,
    get_shop: x => `${base}/marketplace-shop-get/${x}`,
    get_item: x => `${base}/marketplace-item-get/${x}`,
    authentificate: `${base}/authentificate`,
    authorize: `${base}/authorize`,
    transaction: {
        withdraw_order: `${base}/withdraw-order-show`,
        convert_order: `${base}/convert-order-show`,
        checkout_id: id => `${server}/blockchain/checkout-show/${id}`
    },
    get_nft: `${base}/nft-list`,
    nft_transfer: `${base}/nft-transfer`,
    became_seller: `${base}/became-seller`,
    subscription: `${base}/subscriptions-list`,
    subscription_cancel: `${base}/subscription-cancel`
}