<template>
    <div id="choose-monets" class="who-choose">
        <h2
                :class="{
                'f-s-subtitle': $store.getters.is_mobile,
                'f-weight-medium':$store.getters.is_mobile,
                'f-s-title': !$store.getters.is_mobile
            }"
                v-text="get_i18n($store.getters.i_home_choose, 'why_choose')"></h2>
        <div class="wrap-inline_info_card flex">
            <div v-for="column in columnWhoChoose" class="column-inline-info-card">
                <inline-info-card
                        v-for="item in column"
                        :title="item.title"
                        :text="item.text"
                        :help="item.help"
                        :is-i18n="true"
                        i18n-key="i_home_choose"
                        :image="item.image"
                        :is-gradient="!!item.is_gradient"
                        :imageWidth="$store.getters.is_mobile ? '100%':item.width??''"
                        :imageHeight="$store.getters.is_mobile ? 'auto':item.height??''"
                />
            </div>
        </div>
    </div>
</template>

<script>
import utils from "module/utils";
import InlineInfoCard from "mnhome/UI/inlineInfoCard.vue";

export default {
    name: "whoChoose",
    components: {InlineInfoCard},
    data() {
        return {
            who_choose: [
                {
                    title: 'fast_simple_payments',
                    text: ["make_cryptocurrency_payments", "all_they_need_transfer"],
                    help: "eliminates_need_customers_enter",
                    image: require("assets/home/img/why-choose/checkout.webp"),
                    width: '224px',
                    height: '221px'
                },
                {
                    title: "recurring_payments",
                    text: 'offer_cryptocurrency_payments_subscriptions',
                    help: "",
                    image: require("assets/home/img/why-choose/whiteplac.webp"),
                    width: '224px',
                    height: "224px"
                },
                {
                    title: "payment_link",
                    text: "dont_have_website_you_can",
                    help: "",
                    image: require("assets/home/img/why-choose/share.webp"),
                    width: '224px',
                    height: '271px'
                },
                {
                    title: "easy_integration_with",
                    text: "easily_install_our_payment_widget",
                    help: "dont_have_programmer_can",
                    image: require("assets/home/img/why-choose/integration.webp"),
                    width: '224px',
                    height: '224px'
                },
                {
                    title: "instant_in_service",
                    text: "customers_can_pay_using",
                    help: "",
                    image: require("assets/home/img/why-choose/p2p.webp"),
                    width: '224px',
                    height: '371px'
                },
                {
                    title: "access_to_testnet",
                    text: "test_all_integrations_on",
                    help: "",
                    image: require("assets/home/img/why-choose/withdraw.png"),
                    width: '224px',
                    height: '224px'
                },
                {
                    title: "nft_after_payment",
                    help: "can_disable_this_feature",
                    text: "after_payment_instantly_issue",
                    image: require("assets/home/img/why-choose/nftcard.webp"),
                    is_gradient: true,
                    width: '224px',
                    height: '309px'
                },

                {
                    title: "will_help_you_to",
                    text: ['worried_about', "team_of_specialists_will"],
                    help: "",
                    image: ""
                }
            ]
        }
    },
    methods: {
        get_i18n: utils.get_i18n
    },
    computed: {
        columnWhoChoose() {
            let size = window.screen.width
            let lst = this.who_choose
            let count = size > 1116 ? 2 : 3
            if (this.$store.getters.is_mobile) {
                lst = [
                    lst[0], lst[6], lst[2], lst[4],
                    lst[3], lst[1], lst[5], lst[7]
                ]
                return [lst]
            }
            return utils.columnList(lst, count)
        }
    }
}
</script>

<style scoped lang="scss">
.who-choose {
  margin-top: 80px;
  padding-bottom: 49px;
  margin-bottom: 32px;

  h2 {
    margin-bottom: 48px;
  }

  @media screen and (max-width: 1279px) {
    .wrap-inline_info_card {
      justify-content: center;
    }
  }
  @media screen and (max-width: 520px) {
    padding-top: 16px;
    margin-top: 8px;
    padding-bottom: 48px;
    h2 {
      margin-bottom: 32px;
    }
  }

  .column-inline-info-card {
    width: 256px;
    margin-right: 24px;

    @media screen and (max-width: 520px) {
      width: 100%;
      margin-right: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    .inline-info-card {
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>