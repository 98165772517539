<template>
  <div class="errorPage">
    <mn-header back-title="Home" to-back="/" title=""/>
    <h1 class="text-al-center">Not Found</h1>
  </div>
</template>

<script>
import MnHeader from "@/commponents/mnHeader.vue";

export default {
  name: "http404",
  components: {MnHeader}
}
</script>

<style scoped>

</style>