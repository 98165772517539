export default {
    async init_wallet(balances, is_main=true) {
        for (let items of balances) {
            let chain_id = items.chain_id
            let mode = 'crypto'
            if (!chain_id) mode = 'fiat'
            let rb = this.balances[mode][items.currency]
            rb || (rb = this.balances[mode][items.currency] = {});
            let chain = rb[`chain_${items.chain_id}`]
            chain || (chain = rb[`chain_${items.chain_id}`] = {})
            let {balance, on_hold, erc20_address} = items
            let {balance: bb, on_hold: hold} = chain
            typeof bb === 'number' || (bb = 0)
            typeof hold === 'number' || (hold = 0)
            on_hold ? hold += balance : bb += balance
            chain.balance = bb
            chain.on_hold = hold
            chain.erc20_address = erc20_address
            if (mode === 'crypto' && on_hold) {}
            if (mode === 'crypto' && is_main) {
                let b = on_hold ? hold : bb
                if (items.currency.toUpperCase() !== 'USDT') {
                    b = await this.get_calc(b, 'USDT', items.currency.toUpperCase())
                }
                this.main_balance[on_hold?'on_hold':'balance'] += b
                if (!on_hold) this.main_balance.convert_balance = await this.get_calc(this.main_balance.balance, 'USD', 'USDT')
            }
        }
    },
    short_out(text) {

      return text.length > 10 ? `${text.slice(0, 5)}...${text.slice(text.length - 5)}` : text
    },
    balance_out(currency, count) {
      return `${count.toFixed(3)} ${currency.toUpperCase()}`
    }
}