import _public from "module/api/controllers/public";
import user from "module/api/controllers/user";
import seller from "module/api/controllers/seller";
import blockchain from "module/api/controllers/blockchain";
import operator from "module/api/controllers/operator";
import admin from "module/api/controllers/admin";

export default {
    public: _public,
    user,
    seller,
    blockchain,
    operator,
    admin
}
