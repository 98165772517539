import apiUrl from "module/api/api-url";
import {connect, fetch_connect} from "module/api/controllers/main";

export default {
    async publish_seller(token_id, shop_name, currency, price) {
        return await connect(apiUrl.seller.publish_seller, {token_id, shop_name, currency, price}, true).post_json()
    },
    async unpublish_seller(token_id) {
        return await connect(apiUrl.seller.unpublish_seller, {token_id}, true).post_json()
    },
    async create_shop(name, params) {
        return await connect(apiUrl.seller.create_shop, {name, ...params}, true).post_json()
    },
    async update_shop(name, params) {
        return await connect(apiUrl.seller.update_shop, {name, ...params}, true).post_json()
    },
    async get_shop(name) {
        return await connect(apiUrl.seller.get_shop, {name}, true).post_json()
    },
    async get_shops(offset, limit) {
        return await connect(apiUrl.seller.get_shops, {offset, limit}, true).post_json()
    },
    async upload_file(form) {
        return await fetch_connect(apiUrl.seller.image_gallery, form, true).post_json()
    },
    async create_shop_item(shop_name, title, price, currency, description, image_url, stock = null,
                           subscription_pay_period, subscription_trial_period, subscription_unpaid_period, subscription_pay_periods_max) {
        return await connect(apiUrl.seller.create_shop_item, {
            shop_name, title, price, currency, description, image_url, stock, subscription_pay_periods_max,
            subscription_pay_period, subscription_trial_period, subscription_unpaid_period
        }, true).post_json()
    },
    async image_gallery(form) {
        return await fetch_connect(apiUrl.seller.image_gallery, form, true).post_json()
    },
    async get_image_gallery(seller_id, category = "") {
        return await connect(apiUrl.seller.get_image_gallery(seller_id, category)).get_json()
    },
    async get_api_key() {
        return await connect(apiUrl.seller.api_key, {}, true).post_json()
    },
    async balance() {
        return await connect(apiUrl.seller.balance, {}, true).post_json()
    },
    async withdraw_create(amount, wallet_address, chain_id, erc20_address, msg_seller) {
        return await connect(apiUrl.seller.withdraw_create, {
            amount,
            wallet_address,
            chain_id,
            erc20_address,
            msg_seller
        }, true).post_json()
    },
    async orders_withdraw(offset, limit) {
        return await connect(apiUrl.seller.withdraw_orders, {offset, limit}, true).post_json()
    },
    async transaction_history(offset, limit) {
        return await connect(apiUrl.seller.transaction_history, {offset, limit}, true).post_json()
    },
    async integration_create(cms_id, params) {
        return await connect(apiUrl.seller.integration.create, {cms_id, ...params}, true).post_json()
    },
    async integration_list() {
        return await connect(apiUrl.seller.integration.list, {}, true).post_json()
    },
    async integration_remove(cms_id) {
        return await connect(apiUrl.seller.integration.remove, {cms_id}, true).post_json()
    },
    async integration_edit(cms_id, params) {
        return await connect(apiUrl.seller.integration.edit, {cms_id, ...params}, true).post_json()
    },
    async integration_get(cms_id) {
        return await connect(apiUrl.seller.integration.get, {cms_id}, true).post_json()
    },
    async p2pCreate(item_id, price_id) {
        return await connect(apiUrl.seller.p2pCreate, {
            cms_id: 'monets.me',
            [item_id.is_nft ? 'token_id' : 'item_id']: item_id.id,
            price_id
        }, true).post_json()
    },
    async p2pPay(p2p_id, chain_id, erc20_address, estimate = true) {
        return await connect(apiUrl.seller.p2pPay, {
            id: p2p_id,
            chain_id,
            erc20_address,
            estimate
        }, true).post_json()
    },
    async mint_nft(item_id, site_url) {
        return await connect(apiUrl.seller.nft_mint, {
            item_id, site_url
        }, true).post_json()
    },
    async item_delete(id) {
        return await connect(apiUrl.seller.item_delete, {
            id
        }, true).post_json()
    },
    async get_subscriber(offset=0, limit=10) {
        return await connect(apiUrl.seller.subscriber, {offset, limit}, true).post_json()
    }
}