<template>
  <div>
    <p @click="is_show=true" class="user_wallet flex al-center">
      <img v-if="!!get_currency" :src="get_currency" alt="">
      <span class="f-ss-ms f-weight-bold color-black_grey"
            v-text="wallet_address ? walletAddress :  get_i18n($store.getters.i_button,'add_cryptowallet')">
      </span>
    </p>
    <pop-up-wallet
        v-model="is_show"
        v-model:chain_id="chain_id"
        v-model:wallet="wallet_address"
        :chains="chains"
    />
  </div>
</template>

<script>
import PopUpWallet from "@/commponents/popUpWallet";
import utils from "module/utils";
import env from "module/env";
import wallet from "module/wallet";

export default {
  name: "mnWallet",
  components: {PopUpWallet},
  props: {
    user: Object,
    list_chains: Array,
  },
  methods: {
    set_chain_name(id) {
      if (!this.chains.length) return;
      for (let i of [...this.chains]) {
        let {chain_id, name} = i
        i.icon = env.icons.chain[i.chain_id]
        if (chain_id === id) {
          this.chain_id = i
          console.log(id, name, 'Selected')
        }
      }
    },
    set_data(chain_id, wallet_address) {
      this.chain_id = {chain_id}
      this.wallet_address = wallet_address
      this.set_chain_name(chain_id)
    },
    async loadChain() {
      if (!this.list_chains) {
        await this.$store.dispatch("loadChain")
        this.chains = this.$store.state.chains_list
      } else this.chains = this.list_chains
    },
    get_i18n: utils.get_i18n
  },
  async mounted() {
    await this.loadChain()
    if (this.user) {
      this.set_data(this.user.chain_id, this.user.wallet_address)
      return
    }
    try {
      let {chain_id, wallet_address} = this.$store.state.profile
      this.set_data(chain_id, wallet_address)
    } catch (e) {
      console.error(e)
    }
  },
  data() {
    return {
      chain_id: {},
      wallet_address: '',
      is_show: false,
      chains: []
    }
  },
  computed: {
    walletAddress() {
      if (!this.wallet_address) return '';
      return wallet.short_out(this.wallet_address)
    },
    get_currency() {
      return env.icons.chain[this.chain_id.chain_id]
    }
  },
  watch: {
    user: {
      deep: true,
      handler(newVal) {
        this.set_data(newVal.chain_id, newVal.wallet_address)
      }
    },
    "$store.state.profile.wallet_address"() {
      if (!!this.user) return;
      this.wallet_address = this.$store.state.profile.wallet_address
    },
    "$store.state.profile.chain_id"() {
      if (!!this.user) return;
      this.chain_id = {chain_id: this.$store.state.profile.chain_id}
      this.loadChain().then(a => this.set_chain_name(this.chain_id.chain_id))
    }
  }
}
</script>

<style scoped lang="scss">
.user_wallet {
  background: #EEF4F8;
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 10px;

  img {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }

}
</style>