<template>
    <div :id="title" class="how_it">
        <p
                class="main_title f-s-title"
                :style="{
                marginLeft: !$store.getters.is_mobile && isReverse ? 'auto' : ''
            }"
                :class="{
            'f-s-title': !$store.getters.is_mobile,
            'f-s-subtitle':$store.getters.is_mobile,
            'f-weight-medium':$store.getters.is_mobile
            }"
                v-text="isI18n ? get_i18n($store.getters[i18nKey], title) : title"></p>
        <div
                :class="{
            flex: !$store.getters.is_mobile
            }"
                class="jc-sb"
                :style="{
            flexDirection: !$store.getters.is_mobile && isReverse ? 'row-reverse' : ''
        }">
            <div v-if="$store.getters.is_mobile" class="images flex jc-center">
                <div class="image-wrap">
                    <lazy-image type="iob" :show-load="true" :src="image1"/>
                </div>
                <div class="image-wrap" v-if="image2">
                    <lazy-image type="iob" :show-load="true" :src="image2"/>
                </div>
                <div v-if="video2" class="video">
                    <lazy-image
                            :src="video2"
                            v-slot="slotProps"
                            type="iob" :show-load="true"
                            :sloading="true"
                    >
                        <iframe
                                width="234" height="490"
                                :src="slotProps.lazyPath"
                                title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen></iframe>
                    </lazy-image>
                </div>
                <div class="back_radial"></div>
            </div>
            <div class="wrap-points">
                <detail-points
                        v-for="(point, index) in points"
                        :index="index+1"
                        :is-i18n="isI18n"
                        :i18n-key="i18nKey"
                        :help="point.help"
                        :text="point.text"
                        :title="point.title"
                        :is_number="is_points_number"
                        :style="{
                            marginBottom: ($store.getters.is_mobile&&(points.length!==index+1||is_mobile_button) && is_distance_points) || (is_distance_points&&!$store.getters.is_mobile) ? '48px' : ''
                        }"
                />
                <div v-if="!$store.getters.is_mobile||is_mobile_button" class="wrap-button" :style="{
                    marginLeft: is_points_number ? '68px' : '',
                    marginTop: !is_distance_points ? '24px' : ''
                }">
                    <mn-button
                            width="100%"
                            padding="10px 16px"
                            to="/auth/"
                            target="new"
                            :text="get_i18n($store.getters.i_button, 'get_started_now')"
                    />
                </div>
            </div>
            <div v-if="!$store.getters.is_mobile" class="images flex jc-center">
                <div class="image-wrap">
                    <lazy-image type="iob" :show-load="true" :src="image1"/>
                </div>
                <div class="image-wrap" v-if="image2">
                    <lazy-image type="iob" :show-load="true" :src="image2"/>
                </div>
                <div v-if="video2" class="video">
                    <lazy-image
                            :src="video2"
                            v-slot="slotProps"
                            type="iob" :show-load="true"
                            :sloading="true"
                    >
                        <iframe
                                width="234" height="490"
                                :src="slotProps.lazyPath"
                                title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen></iframe>
                    </lazy-image>
                </div>
                <div class="back_radial"></div>
            </div>
        </div>
    </div>
</template>

<script>
import utils from "module/utils";
import DetailPoints from "mnhome/UI/detailPoints.vue";
import MnButton from "@/UI/mnButton.vue";
import LazyImage from "mnhome/UI/lazyImage.vue";

export default {
    name: "detailInformation",
    components: {LazyImage, MnButton, DetailPoints},
    props: {
        title: String,
        points: Array,
        image1: String,
        image2: String,
        video2: String,
        isI18n: Boolean,
        i18nKey: {
            default: 'detailInformation',
            type: String
        },
        isReverse: Boolean,
        is_points_number: {
            type: Boolean,
            default: true
        },
        is_distance_points: {
            type: Boolean,
            default: true
        },
        is_mobile_button: Boolean
    },
    methods: {
        get_i18n: utils.get_i18n,
        onFrame() {
            console.log("LOAD")
        }
    },
    data() {
        return {
            frameLoading: false
        }
    }
}
</script>

<style scoped lang="scss">
.how_it {
  padding-top: 48px;
  padding-bottom: 48px;
  margin-top: 32px;
  @media screen and (max-width: 520px) {
    padding-top: 16px;
    padding-bottom: 16px;
  }
}

.main_title {
  width: 49%;
  margin-bottom: 64px;
  @media screen and (max-width: 520px) {
    width: 100%;
    margin-bottom: 16px;
  }
}

.wrap-points {
  flex: 0.49;
  @media screen and (max-width: 520px) {
    padding-top: 13px;
  }
}

.wrap-button {
  width: 256px;
  @media screen and (max-width: 520px) {
    width: 204px;
  }
}

.images {
  position: relative;
  flex: 0.46;

  .image-wrap {
    z-index: 1;
    flex: 0.5;

    .image {
      width: 100%;
      height: auto;
      border-radius: 12px;
    }

    &:first-child {
      margin-right: 24px;
    }
  }

  .video {
    z-index: 1;
    flex: 0.5;

    .image {
      padding: 8px;
      border-radius: 12px;
      background: #fff;
    }

    iframe {
      border-radius: 12px;
      width: 100%;
      height: 510px;
      z-index: 2;
      position: relative;
    }

  }

  .back_radial {
    position: absolute;
    width: 680px;
    height: 531px;
  }
}

@media screen and (max-width: 520px) {
  .images {
    margin-bottom: 32px;

    .back_radial {
      right: 0;
      left: 0;
      top: 0;
      bottom: 0;
      height: auto;
      width: auto;
    }

    .image-wrap {
      &:first-child {
        margin-right: 16px;
      }
    }

    .video {
      .image {
        height: 100%;
      }

      iframe {
        height: 100%;
      }
    }
  }
}
</style>