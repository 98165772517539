<template>
    <footer class="bg_footer">
        <div class="desktop-container f-s-ms">
            <div class="footer_text color-white jc-sb" :class="{
                'flex-wrap': $store.getters.is_mobile,
                'jc-center': $store.getters.is_mobile,
                flex: $store.state.lang !== 'ru' || !$store.getters.is_mobile,
                'rus-legacy': $store.state.lang === 'ru' && $store.getters.is_mobile
            }">
                <div class="legacy" :class="{
                    flex: $store.state.lang !== 'ru' || !$store.getters.is_mobile
                }">
                    <p @click="target('/terms/')" class="cursorPointer"
                       v-text="get_i18n($store.getters.i_home_footer, 'terms')"></p>
                    <p @click="target('/privacy-policy/')" class="cursorPointer"
                       v-text="get_i18n($store.getters.i_home_footer, 'privacy')"></p>
                </div>
                <p class="cursorPointer" v-text="get_i18n($store.getters.i_home_footer, 'contact')"></p>
            </div>
            <p :class="{
                'text-al-center': $store.getters.is_mobile
            }" class="made_in color-black_grey" v-text="get_i18n($store.getters.i_home_footer, 'made_in')"></p>
        </div>
    </footer>
</template>

<script>
import utils from "module/utils";

export default {
    name: "mnFooter",
    methods: {
        get_i18n: utils.get_i18n,
        target(url) {
            window.open(url, '_self')
        }
    },
}
</script>

<style scoped lang="scss">
footer {
  margin-top: 37px;
}

.footer_text {

}

.desktop-container {
  padding-left: 32px;
  padding-right: 32px;
  @media screen and (min-width: 1280px) {
    padding-left: 92px;
    padding-right: 92px;
  }

  @media screen and (max-width: 520px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.bg_footer {
  background-color: #1C1C1E;
  padding: 16px 0;
}

.made_in {
  margin-top: 16px;
}

.legacy {
  p {
    margin-right: 39px;
  }

  @media screen and (max-width: 520px) {
    p {
      margin-right: 8px;
    }
  }
}

.rus-legacy {
  p {
    margin-right: 0;
    margin-bottom: 8px;
  }
}
</style>