<template>
  <div>
    <textarea :class="{error_field: is_error}" @focus="focus" @blur="blur"
              :style="{height: auto_height ? height : '', overflow: auto_height ? 'hidden' : ''}" class="f-s-ms"
              @input="$emit('update:modelValue', $event.target.value)"
              name="name" :readonly="readonly" :id="'mn-'+name+'-id'" v-bind:value="modelValue"
              :placeholder="placeholder"></textarea>
  </div>
</template>

<script>
export default {
  name: "textareaField",
  props: {
    placeholder: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      default: ""
    },
    modelValue: String,
    auto_height: Boolean,
    is_error: Boolean,
    readonly: Boolean,
  },
  data() {
    return {
      height: ""
    }
  },
  methods: {
    focus() {
      this.height = "0"
      setTimeout(()=>this.height=this.$el.firstElementChild.scrollHeight+'px', 10)
    },
    blur() {
      if (this.$el.firstElementChild.scrollHeight > 100) this.height = ''
    }
  },
  watch: {
    modelValue(newVal) {
      let el = this.$el.firstElementChild
      let {clientHeight, scrollHeight} = el
/*      if (!newVal || newVal.length < 50) {
        this.height = ''
        return
      }*/
      if (scrollHeight >= clientHeight) {
        this.height = `${scrollHeight}px`
      } else {
        this.height = ''
      }
    }
  },
  mounted() {
    if (this.auto_height) {
      this.focus()
    }
  }
}
</script>

<style scoped lang="scss">
.field_title {
  textarea {
    height: 100%;
  }
}

textarea {
  border: 2px solid #F1F1F1;
  border-radius: 8px;
  padding: 8px;
  height: 216px;
  resize: none;
  outline: none;
  width: 100%;
}

textarea::placeholder {
  color: #8B90A3;
}

</style>