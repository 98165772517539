import apiUrl from "module/api/api-url";
import {connect} from "module/api/controllers/main";

export default {
    async chain_list() {
        return await connect(apiUrl.blockchain.chain_list).get_json()
    },
    async exchange_rates(fr, to) {
        return await connect(apiUrl.blockchain.exchange_rates(fr, to)).get_json()
    },
    async nft_owner(token_id) {
        return await connect(apiUrl.blockchain.nft_owner(token_id), {}).get_json()
    },
    async show_checkout(checkout_id) {
        return await connect(apiUrl.blockchain.show_checkout(checkout_id), {}).get_json()
    },
    async rates_calculator(to, from, amount) {
        return await connect(apiUrl.blockchain.rates_calculator(to, from, amount), {}).get_json()
    },
    async subscription_pay(id) {
        return await connect(apiUrl.blockchain.subscription_pay(id), {}, false).get_json()
    },
    async checkout(context) {
        return await connect(apiUrl.blockchain.checkout, {...context}, false).post_json()
    },
    async checkout_v2(context) {
        return await connect(apiUrl.blockchain.checkoutV2, {...context}, false).post_json()
    },
    async subscription(item_id, client_email, client_name, cms_id, site_url) {
        return await connect(apiUrl.blockchain.subscription, {item_id, client_email, client_name, cms_id, site_url}, false).post_json()
    }
}