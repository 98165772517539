function setCrossSubdomainCookie(name, value, years) {
  const assign = name + "=" + encodeURIComponent(value) + ";";
  const d = new Date();
  d.setTime(d.getTime() + (years*12*30*24*60*60*1000));
  const expires = "expires="+ d.toUTCString() + ";";
  const path = "path=/;";
  const domain = "domain="+(x=>x?x[0]:document.domain)(document.domain.match(/[^\.]*\.[^.]*$/)) + ";";
  document.cookie = assign + expires + path + domain;
}

function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        let cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            let cookie = cookies[i].trim();
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}


export default {
    getCookie, setCrossSubdomainCookie
}