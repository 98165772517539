function set_error(types, type, message, time_live) {
    function _(el, message) {
        if (typeof el !== 'object') this[types[type]] = message
        else el.error = message
    }

    console.log(types, type, message)
    if (!type) type = 'default'
    let el = this[types[type]]
    if (el===undefined||el===null && type !== 'default') return set_error.call(this, types, 'default', message, time_live)
    _.call(this, el, message)
    if (!!time_live) setTimeout(()=>_.call(this,  el, ''), time_live * 1000)
}

function get_type(type, set_type) {
    let i18n = this.$store.state.i18n
    if (!i18n.error) {
        console.error("Not loaded i18n")
        return
    }
    let error = {
        message: '',
        type: 'default'
    }
    let error_message = i18n.error[type]
    if (typeof error_message === 'string') {
        error.message = error_message
    } else {
        error.message = error_message.message
        error.type = error_message.type
    }
    console.log(set_type, 'Set type', error)
    if (set_type) error.type = set_type
    return error
}


export default {
    set_error, get_type
}