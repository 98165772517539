<template>
  <div class="errorPage">
    <mn-header back-title="Home" to-back="/" title=""/>
    <h1>Forbidden</h1>
  </div>
</template>

<script>
import MnHeader from "@/commponents/mnHeader.vue";

export default {
  name: "http403",
  components: {MnHeader}
}
</script>

<style scoped></style>