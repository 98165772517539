import utils from "module/utils";

export default {
    footer_target(item) {
        let {url, target, target_url} = item
        const to = link => window.open(link, '_self')
        if (target_url) url = target_url
        if (item.is_use === false) return alert("coming soon")
        if (target.toLowerCase() === 'all') return to(url)
        to(utils.user_menu_target(url, target))
    },
}