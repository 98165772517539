<template>
    <div class="info-card" :style="{
        paddingTop: isBigIcon ? '2px' : ''
    }">
        <div class="info-card-head jc-sb al-center"
             :class="{
                flex: !$store.getters.is_mobile
            }"
        >
            <div v-if="$store.getters.is_mobile" class="icon flex jc-center">
                <img v-if="!is_svg" :src="icon" width="61" height="61" alt="">
                <render-svg v-else
                            :width="$store.getters.is_mobile ? mobileIcon?.width : undefined"
                            :height="$store.getters.is_mobile ? mobileIcon?.height : undefined"
                            :svg="icon"/>
            </div>
            <p class="f-weight-medium"
               :class="{
                    'f-s-subtitle': !$store.getters.is_mobile,
                    'f-m-ms': $store.getters.is_mobile,
                    'text-al-center': $store.getters.is_mobile
               }"
               v-text="is_i18n ? get_i18n($store.getters[i18n_key], title) : title"></p>
            <div v-if="!$store.getters.is_mobile" class="icon">
                <img v-if="!is_svg" :src="icon" width="61" height="61" alt="">
                <render-svg v-else
                            :width="$store.getters.is_mobile ? mobileIcon?.width : undefined"
                            :height="$store.getters.is_mobile ? mobileIcon?.height : undefined"
                            :svg="icon"/>
            </div>
        </div>
        <div class="info-card-text"
             :class="{
                'f-ss-ms': $store.getters.is_mobile,
                'f-s-ms': !$store.getters.is_mobile
            }"
        >
            <p v-for="txt in texts" v-text="is_i18n ? get_i18n($store.getters[i18n_key], txt) : txt"></p>
        </div>
    </div>
</template>

<script>
import RenderSvg from "@/UI/svg/renderSvg.vue";
import utils from "module/utils";

export default {
    name: "infoCard",
    components: {RenderSvg},
    props: {
        title: String,
        icon: String,
        is_svg: Boolean,
        text: [Array, String],
        is_i18n: Boolean,
        i18n_key: {
            type: String,
            default: 'i_info_card'
        },
        isBigIcon: Boolean,
        mobileIcon: Object
    },
    computed: {
        texts() {
            return utils.str_to_list(this.text)
        }
    },
    methods: {
        get_i18n: utils.get_i18n
    }
}
</script>

<style scoped lang="scss">
.info-card {
  border: 2px solid #F1F1F1;
  border-radius: 16px;
  min-height: 267px;
  padding: 16px 19px;
  @media screen and (max-width: 520px) {
      min-height: auto;
      border-radius: 0;
      border: none;
      padding: 16px 0;
  }

  & &-text {
    margin-top: 16px;
    margin-right: 102px;
    line-height: 20px;
    @media screen and (max-width: 520px) {
      line-height: 18px;
      margin-right: 0;
    }

    p {
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

</style>