<template>
    <div class="desktop-container">
        <header-home/>
        <how-can-earn/>
        <who-choose/>
        <detail-information v-for="hiw in how_it_work"
                            :i18n-key="hiw.i18nKey"
                            :title="hiw.title"
                            :points="hiw.points"
                            :is-i18n="true"
                            :image1="hiw.image1"
                            :image2="hiw.image2"
                            :video2="hiw.video2"
                            :is-reverse="!!hiw.is_reverse"
        />
        <with-support v-if="!$store.getters.is_mobile"/>
        <detail-information
            :is-i18n="true"
            :i18n-key="learn_more.i18nKey"
            :points="learn_more.points"
            :is_points_number="!learn_more.is_not_number_points"
            :title="learn_more.title"
            :is_distance_points="false"
            :image1="learn_more.image1"
            :image2="learn_more.image2"
            :is_mobile_button="true"
        />
        <with-support v-if="$store.getters.is_mobile"/>
    </div>
</template>

<script>
import HeaderHome from "mnhome/components/headerHome.vue";
import HowCanEarn from "mnhome/components/howCanEarn.vue";
import WhoChoose from "mnhome/components/whoChoose.vue";
import DetailInformation from "mnhome/components/detailInformation.vue";
import WithSupport from "mnhome/components/withSupport.vue";

export default {
    name: "homePage",
    components: {WithSupport, DetailInformation, WhoChoose, HowCanEarn, HeaderHome},
    data() {
        return {
            how_it_work: [
                {
                    i18nKey: "i_home_work_online",
                    title: "how_it_works",
                    image1: require("assets/home/img/work-online/image1.webp"),
                    image2: require("assets/home/img/work-online/image2.webp"),
                    points: [
                        {
                            title: "place_payment_widget",
                            text: "accept_cryptocurrency",
                            help: "if_you_need_help"
                        },
                        {
                            title: "accept_payments_from",
                            text: "the_customer_pays",
                            help: "can_also_decide_which"
                        },
                        {
                            title: "withdraw_your_earnings",
                            text: "service_allows_you"
                        }
                    ]
                },
                {
                    i18nKey: "i_home_work_offline",
                    title: "how_it_works",
                    image1: require("assets/home/img/work-offline/wine-nft.webp"),
                    video2: this.$store.state.lang === 'ru' ? 'https://www.youtube.com/embed/e1se3YqslKA' : "https://www.youtube.com/embed/83MAry5lpLI",
                    is_reverse: true,
                    points: [
                        {
                            title: "your_product",
                            text: "accept_cryptocurrency",
                            help: ""
                        },
                        {
                            title: "accept_payments",
                            text: "the_customer_can",
                            help: ""
                        },
                        {
                            title: "withdraw_your",
                            text: "the_service"
                        }
                    ]
                }
            ],
            learn_more: {
                i18nKey: "i_home_learn_more",
                title: "learn",
                is_not_number_points: true,
                image1: require("assets/home/img/learn-more/wallet.webp"),
                image2: require("assets/home/img/learn-more/history.webp"),
                points: [
                    {
                        title: '',
                        text: 'youre_worried'
                    },{
                        title: '',
                        text: 'our_team_of'
                    },{
                        title: '',
                        text: 'say_goodbye'
                    },{
                        title: '',
                        text: 'with_monets'
                    },{
                        title: '',
                        text: 'if_desired'
                    },{
                        title: '',
                        text: 'our_service'
                    },{
                        title: '',
                        text: 'you_can_integrate'
                    }
                ]
            }
        }
    }
}
</script>

<style scoped lang="scss">
.desktop-container {
  padding-left: 92px;
  padding-right: 92px;
  @media screen and (max-width: 1279px) {
    padding-left: 32px;
    padding-right: 32px
  }

    @media screen and (max-width: 520px) {
        padding-left: 16px;
        padding-right: 16px;
    }
}
</style>