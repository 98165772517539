<template>
  <div></div>
</template>

<script>
export default {
  name: "renderSvg",
  props: {
    svg: String,
    width: [String, Number],
    height: [String, Number]
  },
  mounted() {
    let el = this.$el
    if (!el) return
    let over = document.createElement("div")
    over.innerHTML = this.svg
    let svg = over.firstElementChild
    if (svg.nodeName.toLowerCase() !== 'svg') return console.warn("[WARNING]",this.svg.slice(0, 30)+'...', 'isn\'t svg')
    if (typeof this.width === 'number') this.width = this.width+'px'
    if (typeof this.height === 'number') this.height = this.height+'px'
    svg.style.width = this.width
    svg.style.height = this.height
    svg.style.display = 'block'
    el.append(svg)
  }
}
</script>

<style scoped>

</style>