<template>
    <div @click="show=!show" :style="{
    border: is_border ? '2px solid #F1F1F1' : ''
  }" :class="{
          'active': show
        }"
         class="cursorPointer select_field flex jc-sb al-center">
        <div class="selected"
             v-text="view_text"
        ></div>
        <mn-drop-down>
            <div @click.stop v-if="!is_modal" v-show="show" class="wrap_select_items">
                <div class="select_items">
                    <div
                            class="select_item"
                            v-for="select_item in select_items"
                            :key="select_item.value"
                            v-text="select_item[get_i18n_key] ? get_i18n($store.getters.i_other, select_item[get_text]) :select_item[get_text]"
                            @click="$emit('update:modelValue', select_item);$emit('change', select_item);show=false"
                    ></div>
                </div>
            </div>
            <pop-up-select v-else @click.stop :get_text="get_text" v-model="show" :items="select_items"/>
        </mn-drop-down>
        <div v-if="is_arrow" class="icon" v-html="icon"></div>
    </div>
</template>

<script>
import env from "module/env";
import PopUpSelect from "@/commponents/popUpSelect";
import MnDropDown from "@/commponents/effects/mnDropDown.vue";
import utils from "module/utils";

export default {
    name: "selectField",
    components: {MnDropDown, PopUpSelect},
    props: {
        select_items: Array,
        modelValue: Object,
        placeholder: String,
        get_key: {
            default: 'id',
            type: String
        },
        get_text: {
            default: 'text',
            type: String
        },
        get_i18n_key: {
            default: "i18n",
            type: String
        },
        is_modal: {
            default: false,
            type: Boolean
        },
        is_border: {
            default: true
        },
        is_arrow: {
            default: true
        }
    },
    data() {
        return {
            show: false,
            icon: env.icons.arrow
        }
    },
    methods: {
        get_i18n: utils.get_i18n
    },
    computed: {
        view_text() {
            let text = this.modelValue[this.get_text]
            if (!text) return this.placeholder
            if (this.modelValue[this.get_i18n_key]) text = this.get_i18n(this.$store.getters.i_other, text)
            return text
        }
    }

}
</script>

<style scoped lang="scss">
.select_field {
  z-index: 1;
  border-radius: 8px;
  padding: 8px;
  outline: none;
  position: relative;
  transition: 0.8s;

  &.active {
    border-radius: 8px 8px 0 0;
  }

  .wrap_select_items {
    height: 0;
    max-height: 20vh;
    position: absolute;
    left: -2px;
    right: -2px;
    top: 45px;
  }

  .select_items {
    transition: 0.5s;
    background-color: white;
    border-radius: 0 0 8px 8px;
    border: solid #f1f1f1;
    border-width: 0 2px 2px 2px;
  }

  &.horizontally {
    padding: 4px 18px;
  }

  .select_item {
    padding: 8px;
  }

  .select_item:hover, :active {
    background-color: #F1F1F1;
  }

  .icon {
    transform: rotate(-90deg);
  }
}
</style>