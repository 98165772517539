<template>
  <div class="flex" :class="{
    'al-center': is_public
  }">
    <div class="image"
         :style="{
        'min-width': is_public ? '72px' : '48px',
        width: is_public ? '72px' : '48px',
        height: is_public ? '72px' : '48px'
      }"
    >
      <mn-image
          :src="src"
          :is_avatar="true"
          :is-equal-width="true"
          :is-ellipse="true"
      />
    </div>
    <div class="information">
      <p class="f-weight-medium"
         :style="{
            fontSize: is_public ? '16px' : '14px'
         }"
         v-text="name"></p>
      <p v-if="!is_public" class="f-ss-ms f-weight-medium" v-text="$store.state.profile.email"></p>
      <p v-if="show_about" class="f-ss-ms about" v-text="about"></p>
      <mn-wallet class="wallet-wrap" v-if="!is_public"/>
    </div>
  </div>
</template>

<script>
import MnImage from "@/UI/mnImage.vue";
import utils from "module/utils";
import wallet from "module/wallet";
import MnWallet from "@/commponents/mnWallet.vue";

export default {
  name: "mnProfile",
  computed: {
    system_wallet() {
      let wallet_address='';
      if (this.user) wallet_address = this.user.system_wallet_address ?? ''
      else wallet_address =  this.$store.state.profile.system_wallet_address ?? ''
      return wallet.short_out(wallet_address).toLowerCase()
    },
    src() {
      if (!this.user) return this.$store.state.profile.photo_url
      return this.user.photo_url
    },
    name() {
      if (!this.user) return this.$store.state.profile.name
      return this.user.name
    },
    about() {
      let t = this.user?.about ?? ''
      let limit = this.$store.getters.is_mobile ? 100 : 250
      if (t.length > limit) t = t.slice(0, limit) + '...'
      return t
    }
  },
  components: {MnWallet, MnImage},
  props: {
    user: {
      type: Object
    },
    is_public: {
      default: false,
      type: Boolean
    },
    show_about: {
      default: false,
      type: Boolean
    }
  },
  methods: {
    get_i18n: utils.get_i18n
  },

}
</script>

<style scoped lang="scss">
.image {
  width: 48px;
  height: 48px;
  margin-right: 12px;
}

.information {
  letter-spacing: 0.02em;

  .system-wallet {
    margin-top: 6px;
    margin-bottom: 8px;

    span {
      &:last-child {
        margin-top: 4px;
      }

      display: block;
    }
  }
  .about {
    margin-top: 4px;
    overflow-wrap: anywhere;
  }
  .wallet-wrap {
    margin-top: 8px;
  }
}
</style>