import {createStore} from 'vuex'
import config from "module/config";
import utils from "module/utils";

let domain = project => document.location.hostname !== 'localhost' ? config.front[project] : 'http://localhost:8080'

/*
{
  url: '', // required,
  permissions: { // required
       is_seller: true
  },
  title: '', // required
  icon: "", // optional
  is_mobile: false, // required
  is_desktop: true, // required
  name: "", // optional
  is_active: false // optional
}
*
*
*
* */

export default createStore({
    state: {
        authenticated: false,
        user: null,
        profile: {
            img: "#"
        },
        shop: undefined,
        is_mobile: false,
        http404: false,
        http403: false,
        lang: 'en',
        i18n: {},
        page_load: false, // future
        chains_list: null,
        menu_urls: {
            monetsme: {
                rules: {
                    is_auth: true
                },
                items: [
                    {
                        url: domain('monetsme') + '/profile/wallet/#crypto',
                        permissions: {
                            is_seller: true,
                        },
                        title: 'wallet',
                        icon: 'wallet',
                        name: 'wallet',
                        is_mobile: true,
                        is_desktop: true

                    },
                    {
                        url: domain('monetsme') + '/profile/history/',
                        permissions: {
                            is_seller: true,
                        },
                        title: 'history',
                        icon: "history",
                        name: 'history',
                        is_mobile: true,
                        is_desktop: true
                    },
                    {
                        url: domain('monetsme') + '/profile/#channel',
                        permissions: {
                            is_seller: true
                        },
                        title: "integrations",
                        icon: "integration",
                        is_new: true,
                        name: "integration",
                        is_mobile: true,
                        is_desktop: true
                    },
                    {
                        url: domain('monetsbox') + '/profile/#subscription',
                        permissions: {},
                        title: "subscription",
                        icon: "subscription",
                        is_new: true,
                        name: "subscription",
                        is_mobile: false,
                        is_desktop: true
                    },
                    {
                        url: domain("monetsbox") + '/profile/',
                        permissions: {
                            is_seller: true
                        },
                        title: 'my_shop',
                        icon: "shop",
                        is_mobile: false,
                        is_desktop: true,
                        name: "my-shop",
                        is_active: false
                    },
                ],
            },
            marketBox: {
                rules: {},
                items: [
                    {
                        url: domain('monetsbox') + '/profile/#about',
                        permissions: {
                            is_seller: true
                        },
                        title: 'about',
                        icon: 'about',
                        is_mobile: true,
                        is_desktop: false
                    },
                    {
                        url: domain('monetsbox') + '/profile/#items',
                        permissions: {
                            is_seller: true
                        },
                        title: 'my_items',
                        icon: 'items',
                        is_mobile: true,
                        is_desktop: false
                    },
                    {
                        url: domain('monetsbox') + '/profile/#nfts',
                        permissions: {
                            is_seller: false
                        },
                        title: "my_nfts",
                        icon: 'nft',
                        is_mobile: true,
                        is_desktop: true
                    },
                    {
                        permissions: {},
                        url: '/settings/profile/',
                        is_error: false,
                        title: 'settings',
                        icon: 'settings',
                        is_mobile: true,
                        is_desktop: true
                    }
/*                    {
                        url: domain('monetsbox') + '/',
                        permissions: {},
                        title: 'all',
                        icon: 'shop',
                        is_mobile: true,
                        is_desktop: true,
                        name: "home"
                    },
                    {
                        url: '/become-a-seller/',
                        permissions: {
                            is_seller: false
                        },
                        title: 'monets',
                        icon: "monets",
                        is_desktop: true,
                        is_mobile: true
                    },*/
                ],
            },
            admin: {
                rules: {
                    is_auth: true,
                    is_admin: true // future
                },
                items: [
                    {
                        url: domain('monetsme') + '/admin/',
                        permissions: {
                            is_admin: true
                        },
                        title: 'admin_panel',
                        icon: 'admin',
                        is_mobile: true,
                        is_desktop: true
                    }
                ],
            }
        }
    },
    getters: {
        is_mobile(state) {
            return state.is_mobile
        },
        profile(state) {
            return state.profile
        },
        i_menu(state) {
            return state.i18n.menu ?? {}
        },
        i_wallet(state) {
            return state.i18n.wallet ?? {}
        },
        i_button(state) {
            return state.i18n.buttons ?? {}
        },
        i_other(state) {
            return state.i18n.other ?? {}
        },
        i_nft_viewer(state) {
            return state.i18n.nft_viewer ?? {}
        },
        i_share_popup(state) {
            return state.i18n.share_popup ?? {}
        },
        i_statuses(state) {
            return state.i18n.statuses ?? {}
        },
        i_history(state) {
            return state.i18n.history ?? {}
        },
        i_profile(state) {
            return state.i18n.profile ?? {}
        },
        i_settings_page(state) {
            return state.i18n.settings ?? {}
        },
        i_additem(state) {
            return state.i18n.add_item ?? {}
        },
        i_operator_page(state) {
            return state.i18n.operator_page ?? {}
        },
        i_home_header(state) {
            return state.i18n.header ?? {}
        },
        i_home_earn(state) {
            return state.i18n.how_can_earn ?? {}
        },
        i_home_choose(state) {
            return state.i18n.who_choose ?? {}
        },
        i_home_work_online(state) {
            return state.i18n.how_it_work_online ?? {}
        },
        i_home_work_offline(state) {
            return state.i18n.how_it_work_offline ?? {}
        },
        i_home_with_support(state) {
          return state.i18n.with_the_support ?? {}
        },
        i_home_learn_more(state) {
          return state.i18n.learn_more ?? {}
        },
        i_home_footer(state) {
            return state.i18n.footer ?? {}
        },
        i_placeholder(state) {
            return state.i18n.placeholder ?? {}
        },
        i_error(state) {
            return state.i18n.error ?? {}
        },
        i_auth(state) {
            return state.i18n.auth ?? {}
        }
    },
    mutations: {
        auth(state, data) {
            state.authenticated = data
        },
        shop(state, data) {
            state.shop = data
        },
        set_status_mobile(state, status) {
            state.is_mobile = status
        },
        set_user(state, user) {
            state.user = user
        },
        set_user_data(state, data) {
            state.profile = data
        },
        notFound(state) {
            state.http404 = true
        },
        forbidden(state) {
            state.http403 = true
        },
        httpReset(state) {
            state.http404 = false
            state.http403 = false
        },
        setLangObj(state, lang_obj) {
            state.i18n = lang_obj
        },
        setLang(state, lang) {
            state.lang = lang
        },
        setActiveMenu(state, config) {
            let {key, index, active} = config
            if (!key || !index) return;
            let list = state.menu_urls[key]
            if (!list) return;
            let url = list[index]
            if (!url) return;
            url.is_active = !!active
            // !!false -> !true -> false
        },
        loadChainList(state) {
            console.log("INIT CHAIN LIST")
            state.chains_list = utils.get_chains().then(a => a.data)
        }
    },
    actions: {
        async loadChain({commit, state}) {
            if (state.chains_list === null) commit('loadChainList')
            if (Array.isArray(state.chains_list)) return state.chains_list
            return await state.chains_list.then(a => state.chains_list = a).catch(a => state.chains_list = [])
        }
    },
    modules: {}
})