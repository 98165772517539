import User from "module/user";
import error from "module/compositionAPI/error";
import env from "module/env";
import cookie from "module/cookie";
import lang from "module/compositionAPI/lang";

export default {
    setLang() {
        for (let i of this.languages) {
            if (i.id.toLowerCase() === this.$store.state.lang) {
                this.language = i
                break
            }
        }
    },
    changeLang(error_label) {
        let user = User.load_data()
        if (!this.$store.state.authenticated) {
            cookie.setCrossSubdomainCookie("lang", this.language.id.toLowerCase())
            document.location.reload()
            return;
        }
        user.update_bio({language: this.language.id.toUpperCase()})
            .then(a => document.location.reload())
            .catch(a => {
                let {message, type} = a
                error.set_error.call(this, {default: error_label}, type, message, 3)
            })
    },
    languages: [
        {id: 'ru', text: 'Русский', short: 'rus', icon: env.icons.flags.ru},
        {id: 'en', text: "English", short: "eng", icon: env.icons.flags.en}
    ],
    check_permission(access) {
        let {is_admin, is_operator, is_seller} = this.$store.state.profile
        let permissions = {is_seller, is_admin, is_operator}
        for (let i in access) {
            if (!(permissions[i] === access[i])) return false
        }
        return true
    },
    async init_user(user, lng, support_lang, base_path_lng) {
        this.$store.commit("auth", true)
        if (this.load_user_data) return;
        this.load_user_data = true
        if (!this.$store.state.profile.id)
            user.load_bio().then(a => {
                this.load_user_data = false
                a.data['is_seller'] = user.is_seller
                a.data['is_admin'] = user.is_admin
                a.data['is_operator'] = user.is_operator
                if (!a.data.language) {
                    user.update_bio({language: lng.toUpperCase()})
                } else if (a.data.language.toLowerCase() !== lng.toLowerCase()) {
                    lng = a.data.language.toLowerCase()
                    lang.safe_load_lang.call(this, lng, support_lang, base_path_lng)
                }
                this.$store.commit('set_user_data', a.data)
            }).catch(a => {
                this.load_user_data = false
                console.error(a)
            })
    }
}