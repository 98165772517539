
import config from "module/config";

let {server} = config
let base = `${server}/admin`

export default {
    total_balance: `${base}/total-balance`,
    total_balance_user_by_id: `${base}/user-balance-by-id/`,
    user_show_withdraw: `${base}/seller-withdraw-orders-list`,
    user_bio_get: `${base}/user-bio-get`,
    user_fee_set: `${base}/user-fee-set`,
    user_find: `${base}/user-find-id-by-email`,
    invest_beneficiaries: `${base}/invest-list-beneficiaries`,
    invest_list_percent: `${base}/invest-list-percent`,
    invest_set_percent: `${base}/invest-set-percent`,
    invest_pay_dividends: `${base}/invest-pay-dividends`,
    dashboard: `${base}/dashboard`
}