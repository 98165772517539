<template>
  <div v-if="debug" class="wrap-dev f-s-ms f-inr">
    <div class="dev bg-blue color-white">
      <div>
        <span v-for="i in count">DEVELOPMENT</span>
      </div>
    </div>
  </div>
</template>

<script>
import config from "module/config";

export default {
  name: "mnDev",
  data() {
    return {
      count: 35
    }
  },
  setup() {
    let {debug} = config
    return {debug}
  }
}
</script>

<style scoped lang="scss">
.wrap-dev {
  padding-top: 24px;
}
.dev {
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;

  & > div {
    padding-left: 5px;
    animation-timing-function: linear;
    animation-name: infinity-text;
    animation-iteration-count: infinite;
    animation-duration: 40s;
  }

  span {
    margin-right: 8px;
  }
}

@keyframes infinity-text {
  0% {
    transform: translateX(0vw);
  }
  100% {
    transform: translateX(-100vw);
  }
}

</style>