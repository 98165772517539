import apiUrl from "module/api/api-url";
import {connect} from "module/api/controllers/main";


export default {
    async register(email, password) {
        return await connect(apiUrl.user.register, {email, password}).post_json()
    },
    async get_bio() {
        return await connect(apiUrl.user.get_bio, {}, true).post_json()
    },
    async set_bio(params) {
        return await connect(apiUrl.user.set_bio, params, true).post_json()
    },
    async get_shops(offset, limits) {
        return await connect(apiUrl.user.get_shops, {offset, limits}).post_json()
    },
    async get_shop_items(shop_name, offset, limits) {
        return await connect(apiUrl.user.get_shop_items, {shop_name, offset, limits}).post_json()
    },
    async get_item(id) {
        return await connect(apiUrl.user.get_item(id)).get_json()
    },
    async get_shop(name) {
        return await connect(apiUrl.user.get_shop(name)).get_json()
    },
    async login(id, code) {
        return await connect(apiUrl.user.authorize, {id, code}).post_json()
    },
    async get_code(email) {
        return await connect(apiUrl.user.authentificate, {email}).post_json()
    },
    async get_nft(offset, limits) {
        return await connect(apiUrl.user.get_nft, {offset, limits}, true).post_json()
    },
    async became_seller() {
        return await connect(apiUrl.user.became_seller, {}, true).post_json()
    },
    transaction: {
        async withdraw_order(id) {
            return await connect(apiUrl.user.transaction.withdraw_order, {id}, true).post_json()
        },
        async checkout_id(id) {
            return await connect(apiUrl.user.transaction.checkout_id(id), {}, false).get_json()
        },
        async convert_order(id) {
            return await connect(apiUrl.user.transaction.convert_order, {id}, true).post_json()
        }
    },
    async nft_transfer(token_id, wallet_address) {
        return await connect(apiUrl.user.nft_transfer, {token_id, wallet_address}, true).post_json()
    },
    async get_subscription(offset=0, limit=10) {
        return await connect(apiUrl.user.subscription, {offset, limit}, true).post_json()
    },
    async cancel_subscription(id) {
        return await connect(apiUrl.user.subscription_cancel, {id}, true).post_json()
    }
}