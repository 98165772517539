<template>
    <div class="point" :class="{
        flex: !$store.getters.is_mobile
    }">
        <div v-if="is_number" class="wrap-icon" :class="{
            flex: $store.getters.is_mobile,
            'jc-center': $store.getters.is_mobile
        }">
            <div class="icon">
                <p class="number text-al-center f-ovm" v-text="number"></p>
                <render-svg :svg="get_back"/>
            </div>
        </div>
        <div class="text-content">
            <div class="text">
                <p v-if="title" class="f-s-subtitle f-weight-medium f-letter title"
                   :class="{
                    'text-al-center': $store.getters.is_mobile
                   }"
                   v-text="isI18n ? get_i18n($store.getters[i18nKey], title) : title"></p>
                <div class="description">
                    <p class="f-s-ms" v-text="isI18n ? get_i18n($store.getters[i18nKey], text) : text"></p>
                    <p v-if="help" class="f-s-ms  help f-italic"
                       v-text="isI18n ? get_i18n($store.getters[i18nKey], help) : help"></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import RenderSvg from "@/UI/svg/renderSvg.vue";
import utils from "module/utils";

export default {
    name: "detailPoints",
    components: {RenderSvg},
    props: {
        index: {
            type: Number,
            default: 1
        },
        title: String,
        isI18n: Boolean,
        i18nKey: String,
        text: String,
        help: String,
        is_number: Boolean
    },
    data() {
        return {
            icons: {
                yellow: "<svg width=\"41\" height=\"30\" viewBox=\"0 0 41 30\" fill=\"none\"\n" +
                    "                 xmlns=\"http://www.w3.org/2000/svg\">\n" +
                    "                <path opacity=\"0.5\"\n" +
                    "                      d=\"M23.4153 3.83108C16.3033 -2.50134 7.23861 1.75996 3.59526 4.68217C-7.86712 8.81638 14.4972 32.577 25.3113 28.9724C36.1253 25.3678 41.9779 19.6599 40.2585 11.062C38.5392 2.46408 32.3054 11.7466 23.4153 3.83108Z\"\n" +
                    "                      fill=\"#FFD60A\"/>\n" +
                    "            </svg>",
                green: "<svg width=\"42\" height=\"27\" viewBox=\"0 0 42 27\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n" +
                    "                <path opacity=\"0.5\"\n" +
                    "                      d=\"M16.8621 0.562333C9.99203 -2.65315 3.27808 8.70411 0.779865 14.7847C-3.43587 29.007 10.6166 14.7847 14.8323 21.8959C19.048 29.0071 38.2531 28.3887 41.0636 21.8959C43.874 15.4031 40.439 13.548 29.6655 12.4658C18.8919 11.3837 25.4497 4.58169 16.8621 0.562333Z\"\n" +
                    "                      fill=\"#1DFF6A\"/>\n" +
                    "            </svg>",
                blue: "<svg width=\"48\" height=\"28\" viewBox=\"0 0 48 28\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n" +
                    "                <path opacity=\"0.5\"\n" +
                    "                      d=\"M19.2846 1.24536C14.8501 -1.44709 4.51999 0.700636 1.98421 3.20638C-2.49843 6.5064 1.12511 26.0849 7.75095 17.5872C10.5971 13.9369 16.486 10.0614 19.2846 22.21C22.0832 34.3587 48.3677 24.9407 47.9961 16.2798C47.7507 10.5602 42.72 4.35028 36.9532 10.0699C32.2749 14.71 27.6281 6.31132 19.2846 1.24536Z\"\n" +
                    "                      fill=\"#0085FF\"/>\n" +
                    "            </svg>"
            }
        }
    },
    computed: {
        number() {
            if (this.index < 10) return '0' + this.index
            return this.index.toString()
        },
        get_back() {
            let idx = this.index % 3
            switch (idx) {
                case 1:
                    return this.icons.yellow
                case 2:
                    return this.icons.green
                default:
                    return this.icons.blue
            }
        }
    },
    methods: {
        get_i18n: utils.get_i18n
    }
}
</script>

<style scoped lang="scss">
.icon {
  position: relative;
  margin-right: 16px;
}

.number {
  position: absolute;
  font-weight: 600;
  font-size: 24px;
  left: 0;
  right: 0;
  line-height: 38px;
}


.description {
  margin-top: 14px;

  p {
    line-height: 20px;
    margin-bottom: 8px;
    .help {
        color: #6d7885;
        line-height: 20px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>