const debug = document.location.hostname === 'mm-test.monets.me' ||
    document.location.hostname === 'localhost' ||
    document.location.hostname === 'dev.monets.me' || document.location.hostname === 'market-dev.monets.me'

const service_data = () => {
    let server = debug ? 'https://io-dev.monets.me' : "https://io.monets.me"
    let v = {
        monetsme: debug ? 'https://dev.monets.me' : "https://monets.me",
        monetsbox: debug ? 'https://market-dev.monets.me' : 'https://market.monets.me'
    }
    let rpc_list = {
        matic: [
            ...(debug ? [
                "https://rpc-mumbai.maticvigil.com/v1/5da403d917a2db6e671238d90f2069a723b206d1",
                "https://polygon-mumbai.infura.io/v3/4458cf4d1689497b9a38b1d6bbf05e78",
                "https://rpc.ankr.com/polygon_mumbai",
                'https://rpc-mumbai.matic.today',
                'https://polygon-mumbai-bor.publicnode.com',
                'https://polygon-mumbai-heimdall-rpc.publicnode.com',
                "https://matic-mumbai.chainstacklabs.com",
                "https://polygon-testnet-rpc.allthatnode.com:8545",
            ] : [
                "https://rpc-mainnet.maticvigil.com/v1/7330d3b431eeb9ab22bf383b81a71b45067197ef",
                "https://polygon-rpc.com",
                'https://polygon-bor.publicnode.com',
                'https://polygon-heimdall-rpc.publicnode.com',
                "https://rpc-mainnet.maticvigil.com/v1/0485d1da216b1f43fd0e9468c829748576d64f8f",
                "https://polygon.llamarpc.com",
                'https://polygon-mainnet-archive.allthatnode.com:8545'
            ])
        ],
        ether: [
            ...(debug ? [
                'https://ethereum-goerli.publicnode.com'
            ]: [
                'https://ethereum.publicnode.com'
            ])
        ],
        bsc: [
            ...(debug ? [
                'https://bsc-testnet.publicnode.com'
            ] : [
                'https://bsc.publicnode.com'
            ])
        ]
    }
    const item = {
        donat: 1,
        price: debug ? 1 : 5,
        float: 2
    }
    return {
        debug, server, front: v, is_checkoutv1: false, rpc_list, item
    }
}

export default service_data()

