import apiUrl from "module/api/api-url";
import {connect} from "module/api/controllers/main";


export default {
        async withdraw_show(offset, limit) {
            return await connect(apiUrl.operator.withdraw_show, {offset, limit}, true).post_json()
        },
        async withdraw_send(order_id, msg_operator) {
            return await connect(apiUrl.operator.withdraw_send, {order_id, msg_operator}, true).post_json()
        },
        async withdraw_reject(order_id, msg_operator) {
            return await connect(apiUrl.operator.withdraw_reject, {order_id, msg_operator}, true).post_json()
        }
    }