<template>
  <div>
    <div
        v-for="(list, category) in $store.state.menu_urls"
    >
      <div  v-if="check(list, $store.state.authenticated)" class="menu-category">
        <div class="logo" v-html="get_icon(category)"></div>
        <mn-menu-item v-for="item in list.items" @error="show_error" :data="item" :key="item.url"/>
      </div>
    </div>
    <pop-up-confirm @cancel="error=''" @confirm="error='';is_confirm=false" v-model="is_confirm" :text="error"/>
  </div>
</template>

<script>
import env from "module/env";
import PopUpConfirm from "@/commponents/popUpConfirm.vue";
import MnMenuItem from "@/commponents/mnMenuItem.vue";
import error from "module/compositionAPI/error";
import user from "module/compositionAPI/user";


let logos = {
  monets: env.icons.logo_monets_black,
  market: env.icons.logo,
}

export default {
  name: "mnMenuList",
  components: {MnMenuItem, PopUpConfirm},
  data() {
    return {
      is_confirm: false,
      error: ''
    }
  },
  methods: {
    check(l, is_auth) {
      let ims = []
      for (let i of l.items) {
        if (user.check_permission.call(this, i.permissions)) ims.push(ims)
      }
      if (ims.length <= 0) return false
      if (l.rules.is_auth === undefined || l.rules.is_auth === null) return true
      return l.rules.is_auth ? is_auth : !is_auth
    },
    show_error(error_type) {
      let {type, message} = error.get_type.call(this, error_type)
      console.log(type, message)
      type = 'error'
      error.set_error.call(this, {error: 'error'}, type, message)
      this.is_confirm = true
    },
    get_icon(category) {
      let logo = logos[category]
      if (!logo) return '';
      let a = document.createElement('div')
      a.innerHTML = logo
      a.firstElementChild.style.width = category ==='monets' ? '101px' : "151px"
      a.firstElementChild.style.height = '20px'
      return a.innerHTML
    },
  }
}
</script>

<style scoped>
.menu-category {
  margin-top: 32px;
}

.menu-item {
  margin-top: 4px;
}
</style>