<template>
  <div v-if="is_show" :class="{
    'bg-white': !is_active,
    'bg-light_grey': is_active
  }" class="menu-item f-s-ms f-weight-medium flex jc-sb al-center"
       @click="target(data.url, data.permissions)">
    <div v-if="!data.action || data.action === 'all'" class="flex al-center">
      <p class="icon svg">
        <img :src="icon" alt="">
      </p>
      <p class="title" v-text="get_i18n($store.getters.i_menu, data.title)"></p>
    </div>
    <div v-else-if="data.action==='lang'" class="">
      <icon-select-field
          v-model="language"
          :select_items="languages"
          :is_img="false"
          class="cursorPointer"
          @change="changeLang('error')"
      />
    </div>
    <p class="other color-blue text-al-center text-uppercase"
       v-text="data.is_new ? get_i18n($store.getters.i_other,  'new'):''"
    ></p>
  </div>
</template>

<script>
import utils from "module/utils";
import error from "module/compositionAPI/error";
import user from "module/compositionAPI/user";
import IconSelectField from "@/UI/iconSelectField.vue";

export default {
  name: "mnMenuItem",
  components: {IconSelectField},
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      icon: "",
      languages: user.languages,
      language: {},
      error: ''
    }
  },
  mounted() {
    if (this.data.icon) {
      let icon_svg = utils.getImage(`img/menu/${this.data.icon}.svg`)
      this.icon = icon_svg
    }
    if (this.data.action === 'lang') this.setLang()
  },
  methods: {
    setLang: user.setLang,
    changeLang: user.changeLang,
    check_permission: user.check_permission,
    get_i18n: utils.get_i18n,
    target(url, access) {
      if (!this.check_permission(access)) {
        return this.$emit("error", "E_FORBIDDEN")
      }
      window.open(url, '_self')
    },

  },
  computed: {
    is_show() {
      let {is_mobile, is_desktop} = this.data
      let ism = this.$store.state.is_mobile
      return this.check_permission(this.data.permissions) && (ism && is_mobile || !ism && is_desktop)
    },
    is_active() {
      return this.$route.name === this.data.name || this.data.is_active
    }
  },
  watch: {
    "$store.state.lang"(v) {
     if (this.data.action === 'lang') this.setLang()
    }
  }
}
</script>

<style scoped lang="scss">
.menu-item {
  padding: 8px 13px;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.5s ease-out;

  .icon {
    margin-right: 8px;
  }

  &:hover {
    background: #f1f1f1;
  }

  &:active {
    background: #e8e8e8;
  }
}
</style>