import cookie from "module/cookie";

async function load_lang(lng = 'en', support_lang = ['ru', 'en'], base_path = 'assets/i18n/') {
    if (this.load_lang) return;
    this.load_lang = true
    let i18n = import(`assets/${base_path}${lng}.json`)
    return i18n.then(a => {
        if (Object.keys(a).length === 0) throw 'Not Found ' + lng
        return {data: a.default, lng}
    }).catch(a => {
        console.warn(a)
        this.load_lang = false
        if (this.load_lang_fail > 10) throw a
        this.load_lang_fail += 1
        lng = support_lang.shift().split('-')[0]
        return load_lang.call(this, lng, support_lang, base_path)
    })
}

async function safe_load_lang(lng, support_lang, base_path) {
    return load_lang.call(this, lng, support_lang, base_path).catch(a => {
        this.load_lang = false
        return load_lang.call(this, 'en', support_lang, base_path)
    }).then(a => {
        if (!a) return;
        this.load_lang = false
        cookie.setCrossSubdomainCookie("lang", lng, 1)
        this.$store.commit('setLangObj', {...a.data})
        this.$store.commit("setLang", lng)

        window.i18n = {...a.data}
        return a
    })
}

async function init_lang(user_lang, base_path) {
    let support_lang = Array.from(navigator.languages)
    let lng;
    if (!!user_lang && !!user_lang.lng) lng = user_lang.lng
    else if (!!cookie.getCookie("lang")) lng = cookie.getCookie("lang")
    else lng = navigator.language.split('-')[0]
    return safe_load_lang.call(this, lng, support_lang, base_path)
}

export default {
    load_lang,
    safe_load_lang,
    init_lang
}