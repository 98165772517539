import jwtDecode from "jwt-decode";
import server from "./api/server";
import cookie from "./cookie";
import config from "./config";

const TOKEN_KEY = config.debug ? 'mn-dev-token' : "mn-token"

class User {
    id = null
    is_seller = false
    username;
    first_name;
    last_name;
    photo;
    token;
    is_new = false

    constructor(id, is_seller, is_operator, is_admin, username, token, is_new, first_name = undefined, last_name = undefined, photo = undefined) {
        this.is_seller = is_seller
        this.username = username
        this.token = token
        this.first_name = first_name
        this.last_name = last_name
        this.photo = photo
        this.id = id
        this.is_new = is_new
        this.is_operator = is_operator
        this.is_admin = is_admin
        this.lng = cookie.getCookie("lang")
    }

    static load_data() {
        let token = cookie.getCookie(TOKEN_KEY)
        // let token = localStorage.getItem(TOKEN_KEY)
        let data;
        token && (data = jwtDecode(token))
        if (!data) {
            cookie.setCrossSubdomainCookie(TOKEN_KEY, null, 0)
            let security_pages = [
                "/add-item/", "/profile/", "/settings/", "/operator/",
                "/settings/profile/", '/profile/wallet/', '/profile/history/'
            ]
            let no_security = [
                '/'
            ]
            for (let path of security_pages) {
                if (path.startsWith(document.location.pathname) && !no_security.includes(document.location.pathname)) {
                    window.open('/auth/', '_self')
                }
            }
            return
        }
        return new User(data.id, data.is_seller, data.is_operator, data.is_admin, data.username, token)
    }

    static save_session(token) {
        cookie.setCrossSubdomainCookie(TOKEN_KEY, token, 5)
        // localStorage.setItem(TOKEN_KEY, token)
    }

    static get_code(email) {
        return server.public.get_code(email).then(r => {
            let {ok, id} = r
            return [id, r['new']]
        })
    }

    static login(id, password) {
        return server.public.auth(id, password).then(r => {
            let {ok, token} = r
            if (!ok) return
            this.save_session(token)
            let data = jwtDecode(token)
            return new User(id, data.is_seller, "", token, r.new)
        })
    }
    //Future remove
    static register(email, password) {
        return server.user.register(email, password).then(r => {
            if (!r.ok) return
            return this.login(email, password)
        })
    }

    logout() {
        cookie.setCrossSubdomainCookie(TOKEN_KEY, '', 0)
        // localStorage.removeItem(TOKEN_KEY)
    }

    check_auth() {
        // let token = localStorage.getItem(TOKEN_KEY)
        let token = cookie.getCookie(TOKEN_KEY)
        return token && (function () {
            let data = jwtDecode(token)
            let {exp} = data
            let date = new Date(exp * 1000)
            let now = new Date(Date.now())
            return date > now
        })()
    }

    async load_bio() {
        return server.user.get_bio()
    }
    //FUTURE
    update_bio(params) {
        return server.user.set_bio(params)
    }
}

export default User
export {
    TOKEN_KEY
}