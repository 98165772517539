<template>
  <mn-pop-up type="swipe_top" v-model:is-show="modelValue" @change="$emit('update:modelValue', modelValue)">
    <div id="buy_item_checkout" class="wrap_form">
      <div class="header_popo_up flex jc-sb al-center">
        <div></div>
        <div></div>
        <div v-html="exit_icon" @click="$emit('update:modelValue', false)"></div>
      </div>

      <form @submit.prevent="set_bio">
        <mn-field :field_required="true" v-model="wallet_address" field="input" field_type="text" name="wallet"
                  :placeholder="get_i18n($store.getters.i_placeholder, 'your_wallet')"
                  :label="get_i18n($store.getters.i_menu, 'wallet')"/>
        <p class="error_field color-red f-inr f-s-ms" v-if="error_wallet"
           v-text="get_i18n($store.getters.i_error, 'e_wallet_address_invalid_format')"></p>
        <mn-field
            :field_required="true"
            v-model="chain"
            field="select_icon"
            field_type="select"
            :select_items="chains"
            name="select_chain"
            :placeholder="get_i18n($store.getters.i_placeholder, 'select_blockchain')"
            label="Blockchains"
            select_get_key="chain_id"
            select_get_text="name"
        />
        <p class="error_field color-red f-inr f-s-ms" v-if="error_chain">Not select</p>
        <div class="flex jc-center field_b">
          <mn-button :text="get_i18n($store.getters.i_button, 'save')" type="submit"/>
        </div>
      </form>
    </div>
  </mn-pop-up>
</template>

<script>
import MnField from "@/commponents/mnField";
import MnButton from "@/UI/mnButton";
import env from "module/env";
import MnPopUp from "@/commponents/mnPopUp";
import utils from "module/utils";

export default {
  name: "popUpWallet",
  components: {MnPopUp, MnButton, MnField},
  props: {
    modelValue: Boolean,
    wallet: String,
    chains: Array,
    chain_id: Object
  },
  data() {
    return {
      exit_icon: env.icons.exit,
      chain: {},
      wallet_address: '',
      error_chain: false,
      error_wallet: false
    }
  },
  methods: {
    get_i18n: utils.get_i18n,
    async set_bio() {
      let {chain_id} = this.chain
      if (!chain_id) {
        this.error_chain = true
        setTimeout(() => this.error_chain = false, 3000)
      }
      try {
        let da = await utils.set_bio({chain_id: parseInt(chain_id), wallet_address: this.wallet_address})
        if (da.ok) {
          this.$emit('update:chain_id', this.chain)
          this.$emit('update:wallet', this.wallet_address)
          this.$emit('update:modelValue', false)
          document.location.reload()
        }
      } catch (e) {
        console.error(e)
        this.error_wallet = true
        setTimeout(() => this.error_wallet = false, 3000)
      }

    }
  },
  mounted() {
    this.chain = this.chain_id
  },
  watch: {
    wallet(newValue) {
      this.wallet_address = newValue
    },
    chain_id: {
      deep: true,
      handler(newValue) {
        this.chain = newValue
      }
    }
  }
}
</script>

<style scoped lang="scss">
form {
  max-width: 520px;
  margin: 0 auto;
}

.field_b {
  margin-top: 16px;
}

</style>