import _public from "module/api/urls/public";
import user from "module/api/urls/user"
import seller from "module/api/urls/seller";
import blockchain from "module/api/urls/blockchain";
import operator from "module/api/urls/operator";
import admin from "module/api/urls/admin";

// Future
/*class Router {
    static public(path, ...args) {

    }
    static user(path, ...args) {

    }
    static seller(path, ...args) {

    }
    static blockchain(path, ...args) {

    }
    static operator(path, ...args) {}
}*/



export default {
    public: _public, user, seller,
    blockchain,
    operator,
    admin
}