<template>
  <div class="f-inr">
    <label class="f-s-ms f-weight-bold" :class="{
      'after-required': field_required,
    }" :for="'mn-'+name+'-id'" v-text="label"></label>
    <slot ref="field_check">
      <input-field :success="success" :is_error="is_error" v-model="field_data" :readonly="field_readonly" @input="progressing"
                   v-if="field.toLowerCase() === 'input'" :placeholder="placeholder || modelValue"
                   :name="name" :type="field_type" :is_auto_complete="is_auto_complete" :required="field_required"/>
      <textarea-field :is_error="is_error" v-model="field_data" @input="progressing" :readonly="field_readonly"
                      v-else-if="field.toLowerCase() === 'textarea'" :placeholder="placeholder || modelValue"
                      :name="name"/>
      <select-field
          v-else-if="field.toLowerCase() === 'select'"
          v-model="modelValue"
          :get_key="select_get_key"
          :get_text="select_get_text"
          :placeholder="placeholder"
          :select_items="select_items"
          @change="$emit('update:modelValue', modelValue)"
      />
      <icon-select-field
        v-else-if="field.toLowerCase() === 'select_icon'"
        v-model="modelValue"
        :get_key="select_get_key"
        :get_text="select_get_text"
        :placeholder="placeholder"
        :select_items="select_items"
        @change="$emit('update:modelValue', modelValue)"
      />
      <copy-field
        v-else-if="field.toLowerCase() === 'copy'"
        :copy_text="modelValue"
        :show_text="text_show"
      />

    </slot>
    <p v-if="is_error || error_message" class="message color-red f-hn f-s-ms" v-text="message_error"></p>
  </div>
</template>

<script>
import InputField from "@/UI/inputField";
import TextareaField from "@/UI/textareaField";
import SelectField from "@/UI/selectField";
import IconSelectField from "@/UI/iconSelectField";
import CopyField from "@/UI/copyField";

// TODO need form component!!

export default {
  name: "mnField",
  components: {CopyField, IconSelectField, SelectField, TextareaField, InputField},

  props: {
    label: String,
    name: String,
    field: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    field_type: {
      type: String,
      default: ""
    },
    field_readonly: Boolean,
    validate: Function,
    modelValue: [String, Object],
    field_required: Boolean,
    select_items: Array,
    select_get_key: String,
    select_get_text: String,
    error_message: String,
    success: Boolean,
    text_show: String,
    is_auto_complete:String
  },
  data() {
    return {
      is_error: false,
      message_field: "",
      field_data: this.modelValue ? this.modelValue : ""
    }
  },
  methods: {
    add_error(is_error, message) {
      this.is_error = is_error
      this.message_field = message
      setTimeout(() => {
        this.is_error = false
        this.message_field = ''
      })
    },
    // TODO Validator
    progressing(e) {
      if (this.validate) {
        let [is_success, message, is_update, new_val] = this.validate(this.field_data)
        if (!is_success) {
          let field = this.$refs.field_check
          this.add_error(!is_success, message)
          if (!is_update) return;
        }
        if (is_update) {
          this.$emit('update:modelValue', new_val)
          this.field_data = new_val
          return;
        }
      }
      this.$emit('update:modelValue', this.field_data)
    }
  },
  watch: {
    modelValue(newValue) {
      this.field_data = newValue
    }
  },
  computed: {
    message_error() {
      return this.message_field || this.error_message
    }
  }
}
</script>

<style scoped lang="scss">
.after-required {
  position: relative;
  &:after {
    position: absolute;
    content: "*";
    color: #FF1D38;
    font-size: 20px;
    top: -6px;
    right: -13px;
  }
}
label {
  margin-bottom: 4px;
}
</style>