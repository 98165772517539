import config from "module/config";

let {server} = config
let base = `${server}/public`

export default {
    get_code: `${base}/authentificate`, //send request for get auth code or with create new user
    auth: `${base}/authorize`, // login/register
    bio: seller_id => `${base}/user-bio-get/${seller_id}`,
    get_image_gallery: (seller_id, category) => `${base}/image-gallery-list/${seller_id}/${category}`,
    marketplace_front: (offset, limit) => `${base}/marketplace-frontpage/${offset}/${limit}`,
    item: item_id => `${base}/marketplace-item-get/${item_id}`,
    items: `${base}/marketplace-items-list`,
    shop: shop_name => `${base}/marketplace-shop-get/${shop_name}`,
    shop_list: `${base}/marketplace-shops-list`,
    nft_by_buyer: `${base}/nft-list-by-buyer-email`,
    p2pGet: x => `${base}/p2pay-get/${x}`,
    nft_view: x => `${base}/nft-view/${x}`,
    nft_info: x => `${base}/nfts-info/${x}`,
    nft_invest_report: x=> `${base}/invest-nft-report-monthly/${x}`,
    user_bio_by_wallet_address: x => `${base}/users-bio-get-by-system-wallets/${x}`,

}