<template>
  <div @click="copy(copy_text);set_success()"
       :style="{
              'border-color': success ? 'green' : '', 'color': success ? 'green' : '',
              border: is_border ? '2px solid #F1F1F1' : ''
       }"
       class="field field-input-full flex jc-sb al-center"
  >
    <input :style="{
      fontSize: sizeText ? sizeText : '',
      fontWeight: weightText ? weightText : '',
    }" type="text" readonly style="color: inherit" :value="show_text">
    <div></div>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
          d="M19 21H8V7H19V21ZM19 5H8C7.46957 5 6.96086 5.21071 6.58579 5.58579C6.21071 5.96086 6 6.46957 6 7V21C6 21.5304 6.21071 22.0391 6.58579 22.4142C6.96086 22.7893 7.46957 23 8 23H19C19.5304 23 20.0391 22.7893 20.4142 22.4142C20.7893 22.0391 21 21.5304 21 21V7C21 6.46957 20.7893 5.96086 20.4142 5.58579C20.0391 5.21071 19.5304 5 19 5ZM16 1H4C3.46957 1 2.96086 1.21071 2.58579 1.58579C2.21071 1.96086 2 2.46957 2 3V17H4V3H16V1Z"
          :fill="success ? 'green' : 'black'"/>
    </svg>
  </div>
</template>

<script>
import utils from "module/utils";

export default {
  name: "copyField",
  props: {
    copy_text: String,
    show_text: String,
    is_border: {
      type: Boolean,
      default: true
    },
    sizeText: {
      type: String,
      default: ''
    },
    weightText: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      success: false
    }
  },
  methods: {
    set_success() {
      this.success = true
      setTimeout(() => this.success = false, 3000)
    }
  },
  setup() {
    let {copy} = utils
    return {copy}
  }
}
</script>

<style scoped lang="scss">
.field {
  border-radius: 8px;
  padding: 8px;
  outline: none;

  input {
    flex: auto;
    outline: none;
    border: none;
    cursor: pointer;
  }

  div {
    flex: 0.1;
  }
}
</style>