<template>
    <div
            class="f-inr mb-language"
    >
        <div :style="{
      top: config.debug ? '22px' : 0
    }" class="fixed bg-white flex jc-sb al-center">
            <render-svg v-if="(!$store.state.authenticated||$route.path==='/')&&$route.path !== '/auth/'"
                        @click="target_home" :svg="logo" width="130px" height="30px"/>
            <div v-else></div>
            <div class="profile_action flex al-center">
                <select-field
                        get_text="short"
                        :is_arrow="false"
                        v-model="language"
                        :select_items="languages"
                        :is_modal="false"
                        class="horizontally text-al-center cursorPointer color-grey f-weight-medium f-s-ms f-inr"
                        @change="changeLang('error')"
                />
                <div
                        v-if="!$store.state.authenticated&&$route.path !== '/auth/'"
                        @click="target_home('/auth/')"
                        class="f-s-ms f-weight-medium profile-action cursorPointer text-underline"
                        v-text="get_i18n($store.getters.i_menu, 'auth')"
                ></div>
                <div v-if="$store.state.authenticated&&$route.path==='/'"
                     @click="target_home('/profile/')"
                     class="f-s-ms f-weight-medium profile-action cursorPointer text-underline"
                     v-text="get_i18n($store.getters.i_menu, 'profile')"
                >
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import RenderSvg from "@/UI/svg/renderSvg.vue";
import SelectField from "@/UI/selectField.vue";
import user from "module/compositionAPI/user";
import env from "module/env";
import config from "module/config";
import utils from "module/utils";

export default {
    name: "mobileMenu",
    props: {
        target: {
            default: 'push',
            type: String
        }
    },
    computed: {
        config() {
            return config
        }
    },
    components: {SelectField, RenderSvg},
    data() {
        return {
            languages: user.languages,
            language: {},
            logo: env.icons.logo_monets_black,
        }
    },
    mounted() {
        this.setLang()
    },
    methods: {
        setLang: user.setLang,
        changeLang: user.changeLang,
        target_home(url) {
            if (typeof url !== 'string') url = undefined
            if (this.target === 'push' && url) return this.$router.push(url)
            if (url) return window.open(url, '_self')
            window.open('/', this.$route.path === '/' || this.$route.path === '/auth/'  ? '_self' : '_blank')
        },
        get_i18n: utils.get_i18n
    },
    watch: {
        "$store.state.lang"(v) {
            this.setLang()
        }
    }
}
</script>

<style scoped lang="scss">
.mb-language {
  padding-top: 56px;

  .fixed {
    padding: 10px 16px;
    position: fixed;
    z-index: 9999;
    left: 0;
    right: 0;
  }
}

.profile-action {
  margin-left: 12px;
}
</style>