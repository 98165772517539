import env from "module/env";
import axios from "axios";
import User from "module/user";

let user_error = [
    'E_TOKEN_VERIFY_NO_USER_FOUND', "E_BAD_TOKEN",
    "E_TOKEN_EXPECTED_BUT_SELLER_KEY_PASSED", "E_TOKEN_HAS_NO_ID",
    "E_TOKEN_VERIFY_NO_USER_FOUND", "E_TOKEN_VERIFY_BAD_SECRET", 'E_INVALID_TOKEN'
]

const task_connecting = {}

function connect(url, params, is_authenticate) {
    return {
        async post_json(content_type) {
            let confing = {}
            if (is_authenticate || content_type) {
                confing.headers = {
                    Authorization: is_authenticate ? `Bearer ${env.get_token()}` : undefined,
                    "Content-Type": content_type
                }
            }
            try {
                return (await axios.post(url, params, confing)).data
            } catch (e) {
                let error_type = e.response.data.error
                if (user_error.includes(error_type)) {
                    let u = new User()
                    u.logout()
                    window.open("/auth/?next=" + encodeURIComponent(document.location.href), '_self')
                    return
                }
                let error = {
                    message: "",
                    type: "default"
                }
                console.log(e, error_type)
                let error_message = window.i18n.error[error_type]
                if (!error_message) {
                    error.message = error_type.replace("E", '').replaceAll("_", ' ').toLowerCase().trim()
                    error.type = 'default'
                }
                else if (typeof error_message === 'string') {
                    error.message = error_message
                } else {
                    error.message = error_message.message
                    error.type = error_message.type
                }
                throw error
            }
        },
        async get_json() {
            return (await axios.get(url, {
                params
            })).data
        }
    }
}

function fetch_connect(url, body, is_auth) {
    return {
        async post_json() {
            let data = await fetch(url, {
                method: "POST",
                body,
                headers: {Authorization: is_auth ? `Bearer ${env.get_token()}` : undefined}
            })
            data = await data.json()
            return data
        }
    }
}

function processing_connect(connect_id, url, params, is_auth, is_get) {
    return new Promise((resolve, reject) => {
        let connecting = task_connecting[connect_id]
        if (connecting) {
            clearTimeout(connecting)

        }
        task_connecting[connect_id] = setTimeout(
            () =>
                resolve(connect(url, params, is_auth)
                    [is_get ? 'get_json' : 'post_json']()
                ), 800)
    })
}

export {
    task_connecting, connect, fetch_connect, processing_connect
}