import User from "module/user";
import cookie from "module/cookie";
import lang from "module/compositionAPI/lang";
import _user from "module/compositionAPI/user"

export default {
    async init_page() {
        this.$store.commit('httpReset')
        const user = User.load_data()
        let base_path_lng = 'home/i18n/'
        let support_lang = Array.from(navigator.languages)
        let lng = await lang.init_lang.call(this, user, base_path_lng)
        window.screen.width <= 520 && this.mobile_load()
        this.is_seller = Boolean(user?.is_seller) ? '1' : '0'
        if (user && user.check_auth()) {
            _user.init_user.call(this, user, lng.lng, support_lang, base_path_lng)
        }
    },
}