<template>
    <main>
        <mn-dev/>
        <div :class="{
      'desktop-wrap':!$store.getters.is_mobile,
      'mobile-wrap': $store.getters.is_mobile
    }" :style="{
      paddingTop: !$store.getters.is_mobile ? '65px' : ''
    }">
            <mobile-menu v-if="$store.getters.is_mobile" target="new"/>
            <desktop-menu
                    v-if="!$store.getters.is_mobile"
                    :links="links"
                    target="new"
            />
            <div class="content" :class="{
        'flex-all': !$store.getters.is_mobile
      }">
                <router-view v-if="!$store.state.http403&&!$store.state.http404"
                             class="f-inr bg-white"/>
                <http403 v-else-if="$store.state.http403"/>
                <http404 v-else/>
            </div>
        </div>
        <mn-footer/>
    </main>
</template>
<script>
import User from "module/user";
import urls from "module/urls";
import app from "module/compositionAPI/homeApp";
import Http403 from "@/commponents/http403.vue";
import Http404 from "@/commponents/http404.vue";
import utils from "module/utils";
import MnDev from "monets.me/components/mnDev.vue";
import user from "module/compositionAPI/user";
import DesktopMenu from "@/commponents/descktop/desktopMenu.vue";
import MobileMenu from "@/commponents/mobile/mobileMenu.vue";
import MnFooter from "mnhome/components/mnFooter.vue";
//img, title, shop_title, product_id,
export default {
    name: "App",
    components: {MnFooter, MobileMenu, DesktopMenu, MnDev, Http404, Http403},
    data() {
        return {
            is_seller: '-1',
            ...(app.variables),
            links: [
                {
                    link: '/#earn-monets',
                    text: 'earn_more_with_monets'
                },
                {
                    link: '/#choose-monets',
                    text: 'why_choose_monets'
                },
                {
                    link: '/#how_it_works',
                    text: 'how_it_works'
                },
            ]
        }
    },
    methods: {
        change_lang(lng) {
            this.is_lang = false
            this.language.id = lng
            user.changeLang.call(this, "error_message")
        },
        mobile_load() {
            this.$store.commit('set_status_mobile', true)
        },
        init_page: app.init_page,
        get_i18n: utils.get_i18n,
        logout() {
            let u = User.load_data()
            u && u.logout()
            window.open('/', "_self")
        }
    },
    mounted() {
        window.addEventListener("load", () => this.init_page())
    },
    watch: {
        '$route.path': {
            handler: function (url) {
                this.init_page()
            },
            deep: true,
            immediate: true
        }
    },
    setup() {
        let {footer_target} = urls
        return {footer_target}
    }
}
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Overpass+Mono:wght@600&display=swap');

.f-ovm {
  font-family: 'Overpass Mono', monospace;
}

body {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  min-height: 100vh;
  font-size: 18px;
  color: #1C1C1E;
  overflow-x: hidden;
}

main {
  min-height: 100vh;
  padding-bottom: 16px;
}

main > .desktop-wrap {
  & > .content {
    .content-wrap {
      margin: 16px auto 0;
      padding: 16px 32px;
      border: 2px solid #DEDEDE;
      border-radius: 16px;
    }
  }
}

main > .mobile-wrap {
  & > .content {
    .content-wrap {
      margin: 0;
      padding: 16px;
      border: none;
      border-radius: 0
    }
  }
}

button {
  font-weight: 700;
  border: none;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: inherit;
  font-family: inherit;
}

.container {
  max-width: 1120px;
  margin: 0 auto;
}

.mobile-container {
  max-width: 520px;
  margin: 0 auto;
}

.desktop-container {
  max-width: 1280px;
  margin: 0 auto;
}

header {
  position: relative;

  .container {
    position: relative;
  }

  .container {
    border-radius: 16px;
    padding: 12px 56px 12px 16px;
  }

}

footer {
  margin-bottom: -16px;
}

nav {
  a {
    margin-right: 32px;
  }
}
</style>
