<template>
  <transition :name="'effect-'+type">
    <div class="wrap_window flex" :class="{
    'al-end': type === 'swipe_top',
    'jc-center': type === 'scale-center',
    'al-center': type === 'scale-center'
  }" @click="$emit('update:isShow', false);$emit('change')" v-if="isShow" v-show="!hidden">
      <div class="content-pop_up" :style="{
      height: content_height,
      width: content_width
    }" @click.stop :class="[type, `bg-${background}`]">
        <slot></slot>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "mnPopUp",
  props: {
    isShow: {
      default: false,
      type: Boolean
    },
    hidden: {
      default: false,
      type: Boolean
    },
    type: String,
    background: {
      type: String,
      default: 'white'
    },
    content_height: String,
    content_width: String
  }
}
</script>

<style scoped lang="scss">
.wrap_window {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  background: #282A2C23;

  .content-pop_up {
    cursor: initial;
    width: 100%;

    &.swipe_top {
      height: 80%;
      border-radius: 16px 16px 0 0;
      padding: 16px;
    }

    &.swipe_left, &.swipe_right {
      height: 100%;
      padding: 16px;
      overflow: auto;
    }

    &.scale-center {
      height: auto;
      width: 75%;
      border-radius: 16px;
    }

    &.window {

    }
  }
}
//Effect Swipe Top
.effect-swipe_top-enter-active,
.effect-swipe_top-leave-active {
  transition: 0.5s ease-in;
}
.effect-swipe_top-enter-from {
  margin-bottom: -100vh;
}
.effect-swipe_top-enter-to {
  margin-bottom: 0;
}
.effect-swipe_top-leave-from {
  margin-bottom: 0;
}
.effect-swipe_top-leave-to {
  margin-bottom: -100vh;
}

//Effect Swipe Left

.effect-swipe_left-enter-active,
.effect-swipe_left-leave-active {
  transition: 0.5s ease-in;
}
.effect-swipe_left-enter-from {
  transform: translateX(-100vw);
}
.effect-swipe_left-enter-to {
  transform: translateX(0);
}
.effect-swipe_left-leave-from {
  transform: translateX(0);
}
.effect-swipe_left-leave-to {
  transform: translateX(-100vw);
}

// Effect Swipe Right

.effect-swipe_right-enter-active,
.effect-swipe_right-leave-active {
  transition: 0.5s ease-in;
}
.effect-swipe_right-enter-from {
  transform: translateX(100vw);
}
.effect-swipe_right-enter-to {
  transform: translateX(0);
}
.effect-swipe_right-leave-from {
  transform: translateX(0);
}
.effect-swipe_right-leave-to {
  transform: translateX(100vw);
}

//Effect center scale

.effect-scale-center-enter-active,
.effect-scale-center-leave-active {
  transition: 0.5s ease-in;
}
.effect-scale-center-enter-from {
  opacity: 0;
}
.effect-scale-center-enter-to {
  opacity: 1;
}
.effect-scale-center-leave-from {
  opacity: 1;
}
.effect-scale-center-leave-to {
  opacity: 0;
}

</style>