<template>
  <mn-pop-up
      v-model:is-show="modelValue"
      type="swipe_top"
      @change="$emit('update:modelValue', modelValue)"
  >
    <div class="pop_up-select_items">
      <div class="item_search"></div>
      <div class="select_items">
        <div class="item flex al-center"
             @click="$emit('select', item);$emit('update:modelValue', false)"
             v-for="item in f_items"
        >
          <div class="icon" v-html="item.is_svg ? item[get_icon] : `<img src='${item[get_icon]}' alt=''>`"></div>
          <div class="text f-inr f-size-small f-weight-bold" v-text="item[get_text]"></div>
        </div>
      </div>
    </div>
  </mn-pop-up>
</template>

<script>
import MnPopUp from "@/commponents/mnPopUp";

export default {
  props: {
    modelValue: {type: Boolean, default: false},
    items: {
      type: Array,
      default: []
    },
    get_text: {
      type: String,
      default: 'text'
    },
    get_icon: {
      type: String,
      default: "icon"
    }
  },
  name: "popUpSelect",
  components: {MnPopUp},
  computed: {
    f_items() {
      return this.items
    }
  }
}
</script>

<style scoped lang="scss">
.item {
  padding: 4px;
  border-radius: 8px;
  background: #fefefe;
  margin-bottom: 16px;
}
.icon {
  padding: 4px;
  margin-right: 4px;
  line-height: 0;
}
</style>