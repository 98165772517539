import config from "module/config";

let {server} = config
let base = `${server}/seller`

export default {
    create_shop: `${base}/marketplace-shop-create`,
    update_shop: `${base}/marketplace-shop-set`,
    get_shops: `${base}/marketplace-shops-list`,
    get_shop: `${base}/marketplace-shop-get`,
    upload_file: `${base}/image-upload`,
    create_shop_item: `${base}/marketplace-item-create`,
    image_gallery: `${base}/image-gallery-upload`,
    get_image_gallery: (seller_id, category) => `${base}/image-gallery-list/${seller_id}/${category}`,
    api_key: `${base}/key-generate`,
    balance: `${base}/balance`,
    withdraw_create: `${base}/withdraw-order-create`,
    withdraw_orders: `${base}/withdraw-orders-list`,
    transaction_history: `${base}/transactions-list`,
    integration: {
        create: `${base}/integration-create`,
        list: `${base}/integrations-list`,
        remove: `${base}/integration-remove`,
        get: `${base}/integration-get`,
        edit: `${base}/integration-edit`
    },
    p2pCreate: `${base}/p2pay-create`,
    p2pPay: `${base}/p2pay-pay`,
    publish_seller: `${base}/marketplace-nft-publish`,
    unpublish_seller: `${base}/marketplace-nft-unpublish`,
    nft_mint: `${base}/marketplace-nft-mint-for-item`,
    item_delete: `${base}/marketplace-item-delete`,
    subscriber: `${base}/subscribers-list`
}