<template>
    <div>
        <input :class="[inp_class, {'error_field': is_error, 'success_field': success}]"
               class="f-inr"
               v-bind:readonly="readonly"
               @input="$emit('update:modelValue', $event.target.value);range_change($event.target.value)"
               :name="name" :id="'mn-'+name+'-id'"
               :type="type"
               :placeholder="placeholder"
               :value="modelValue"
               v-bind:required="required"
               :autocomplete="is_auto_complete"
               :pattern='type === "number" ? "[0–9]*" : undefined'
               :inputmode='type === "number" ? "decimal" : undefined'
        >
        <input v-if="type === 'number_step'" hidden class="ranges"
               type="text" :value="modelValue" name="ranges"
               @input="$emit('update:modelValue', $event.target.value)">
    </div>
</template>
<script>
import $ from 'jquery'
import ionRangeSlider from 'ion-rangeslider'

export default {
    name: "inputField",
    props: {
        type: {
            type: String,
            default: "text"
        },
        placeholder: {
            type: String,
            default: ""
        },
        name: {
            type: String,
            default: ""
        },
        modelValue: {
            type: [String, Number],
            default: ""
        },
        readonly: Boolean,
        is_error: Boolean,
        required: Boolean,
        success: Boolean,
        is_auto_complete: String,
        inp_class: {
            default: ['f-s-ms'],
            type: Array
        }
    },
    data() {
        return {
            ranges_el: null,
            ranges_active: false,
        }
    },
    methods: {

        range_change(newVal) {
            if (this.type === 'number_step') {
                let a = $(this.ranges_el).data('ionRangeSlider')
                a.update({
                    from: parseFloat(newVal)
                })
            }
        },
        ranges_method(el) {
            $(el).ionRangeSlider({
                min: 0,
                max: 5000,
                from: parseFloat(this.modelValue),
                extra_classes: 'field-input-ranges',
                onChange: (data) => {
                    let data_range = $(el).data('ionRangeSlider')
                    if (this.readonly) {
                        data_range.update({
                            from: this.modelValue
                        })
                        return
                    }
                    let {from, max} = data
                    if (from > max / 1.2) {
                        data_range.update({
                            max: max * 2
                        })
                    }
                    this.$emit('update:modelValue', from.toString())
                    this.$emit('input', from.toString())
                }
            })
        }
    },
    mounted() {
        let a = this.$el.lastElementChild
        let target = a.name
        console.log(target)
        if (target === 'ranges' && this.type === 'number_step') {
            this.ranges_el = a
            this.ranges_method(a)
        }
    },

}
</script>

<style scoped lang="scss">
div {
  position: relative;
}

input {
  border: 2px solid #F1F1F1;
  border-radius: 8px;
  padding: 8px;
  outline: none;
  width: 100%;

  &.mode_height {
    border: 0;
    width: 0;
    height: 0;
    padding: 0;
    margin: 0;
    position: absolute;
  }
}

</style>