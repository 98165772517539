<template>
  <div class="wrap_header flex al-center jc-sb">
    <mn-back
        :icon="backIcon"
        :to="toBack"
        :title="backTitle"
        :style="{visibility:isShowBack?'':'hidden'}"
        class="flex-8"
        :font-size="backFontSize"
        :font-family="backFontFamily"
        @click="back_action?$emit('back'):0"
    />
    <p class="f-inr f-s-bs" v-text="title"></p>
    <slot>
      <div
          class="flex-8 text-al-end icon_right"
          v-html="iconRight"
          :style="{visibility:isShowRightIcon?'':'hidden'}"
      ></div>
    </slot>
  </div>
</template>

<script>
import MnBack from "@/commponents/mnBack";
import env from "module/env";

export default {
  name: "mnHeader",
  components: {MnBack},
  props: {
    backTitle: String,
    backIcon: {
      default: env.icons.arrow,
      type: String
    },
    title: String,
    iconRight: String,
    toBack: String,
    isShowBack: {type: Boolean, default: true},
    isShowRightIcon: {
      type: Boolean,
      default: false
    },
    backFontSize: {
      type: String,
      default: 's-ms'
    },
    backFontFamily: {
      type: String,
      default: 'hc'
    },
    back_action: Boolean
  }
}
</script>

<style scoped lang="scss">

</style>