<template>
  <mn-pop-up v-model:is-show="modelValue" :content_width="$store.state.is_mobile?'80%':'320px'" type="scale-center"
             @change="$emit('update:modelValue', modelValue)">
    <div class="content-confirm">
      <mn-header :title="get_i18n($store.getters.i_other, 'confirm_window')" to-back="#" @back="$emit('update:modelValue', false);$emit('cancel')" :back_action="true"/>
      <p class="f-m-ms text-al-center f-letter f-inr" v-text="text"></p>
      <div class="flex jc-center mn_button">
        <mn-button
            :text="get_i18n($store.getters.i_other, 'confirm')"
            :is_error="is_fail"
            :is_success="is_success"
            :is_loading="is_loading"
            @click="$emit('confirm')"
        />
      </div>
    </div>
  </mn-pop-up>
</template>

<script>
import MnPopUp from "@/commponents/mnPopUp.vue";
import MnHeader from "@/commponents/mnHeader.vue";
import MnButton from "@/UI/mnButton.vue";
import utils from "module/utils";

export default {
  name: "popUpConfirm",
  components: {MnButton, MnHeader, MnPopUp},
  props: {
    is_loading: Boolean,
    is_success: Boolean,
    is_fail: Boolean,
    modelValue: Boolean,
    text: String,
  },
  methods: {
    get_i18n:utils.get_i18n
  }
}
</script>

<style scoped>
.content-confirm {
  padding: 12px;
}
p,
.mn_button {
  margin-top: 12px;
}
</style>