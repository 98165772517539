<template>
  <div :style="{
    width: width,
    height: !isEllipse && !isEqualWidth ? height : '',
    borderRadius: !isEllipse ? borderRadius : '50%',
    paddingTop: isEllipse || isEqualWidth ? '100%' : ''
  }" class="image_wrap">
    <div class="main_img">
      <slot name="before"></slot>
      <slot>
        <div class="image">
          <div v-show="is_loading" class="flex jc-center al-center img-loading" v-html="loading"></div>
          <img
              @load="onLoad"
              @loadstart="onStartOnload"
              @error="onError"
              :src="imageSrc" alt=""
          >
        </div>
      </slot>
      <slot name="after"></slot>
    </div>
  </div>
</template>

<script>
import env from "module/env";
import utils from "module/utils";

export default {
  name: "mnImage",
  props: {
    width: [String, Number],
    height: [String, Number],
    isEqualWidth: {type: Boolean, default: true},
    isEllipse: Boolean,
    borderRadius: {
      default: '8px',
      type: [String, Number]
    },
    src: {
      type: [String, null],
      required: true
    },
    is_avatar: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onLoad() {
      this.is_loading = false
    },
    onError() {
      if (this.is_avatar) {
        let a = Array.from(this.avatar_list)
        let img = a[utils.random(a.length-1)]
        this.errorImage = utils.getImage(`img/avatar/${img}.jpg`)
      }
    },
    onStartOnload() {
      this.is_loading = true
    }
  },
  data() {
    return {
      is_loading: false,
      errorImage: '',
      loading: env.icons.monets_coin,
      avatars: {
        "Cranks": 5,
        "No Comments": 7,
        "No gravity": 7,
        "Teamwork":17,
        "Upstream": 17
      }
    }
  },
  mounted() {
    this.onStartOnload()
    if (!this.src) this.onError()
  },
  computed: {
    imageSrc() {
      return !this.errorImage ? this.src : this.errorImage
    },
    avatar_list() {
      let l = []
      for (let i in this.avatars) {
        l.push(i)
        for (let j=1; j<=this.avatars[i];j++) {
          l.push(`${i}-${j}`)
        }
      }
      return l
    }
  },
  watch: {
    src() {
      this.errorImage = ''
    }
  }
}
</script>

<style scoped lang="scss">
.image_wrap {
  overflow: hidden;
  position: relative;

  .main_img {
    width: 100%;
    height: 100%;

    .image {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;

      .img-loading {
        padding: 6px;
        width: 100%;
        height: 100%;
        background: #8d8e9a;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
</style>