import apiUrl from "module/api/api-url";
import {connect} from "module/api/controllers/main";

export default {
    async get_code(email) {
        return await connect(apiUrl.public.get_code, {email}).post_json()
    },
    async auth(id, code) {
        return await connect(apiUrl.public.auth, {id, code}).post_json()
    },
    async bio(seller_id) {
        return await connect(apiUrl.public.bio(seller_id), {}).get_json()
    },
    async get_image_gallery(seller_id, category) {
        return await connect(apiUrl.public.get_image_gallery(seller_id, category), {}).get_json()
    },
    async marketplace_front(offset, limit) {
        return await connect(apiUrl.public.marketplace_front(offset, limit), {}).get_json()
    },
    async item(item_id) {
        return await connect(apiUrl.public.item(item_id), {}).get_json()
    },
    async items(shop_name, offset, limit) {
        return await connect(apiUrl.public.items, {shop_name, offset, limit}).post_json()
    },
    async shop(shop_name) {
        return await connect(apiUrl.public.shop(shop_name), {}).get_json()
    },
    async shop_list(offset, limit) {
        return await connect(apiUrl.public.shop_list, {offset, limit}, {}).post_json()
    },
    async nft_by_buyer(email, offset, limit) {
        return await connect(apiUrl.public.nft_by_buyer, {email, offset, limit}, {}).post_json()
    },
    async p2pGet(p2p_id) {
        return await connect(apiUrl.public.p2pGet(p2p_id), {}).get_json()
    },
    async nft_view(token_id) {
        return await connect(apiUrl.public.nft_view(token_id), {}).get_json()
    },
    async nft_info(token_id) {
        return await connect(apiUrl.public.nft_info(token_id), {}).get_json()
    },
    async nft_invest_report(nft_token) {
      return await connect(apiUrl.public.nft_invest_report(nft_token), {}).get_json()
    },
    async user_bio_by_wallet_address(wallet_address) {
        return await connect(apiUrl.public.user_bio_by_wallet_address(wallet_address), {}).get_json()
    },
}