<template>
    <div class="with-support">
        <h2 :class="{
          'f-s-title': !$store.getters.is_mobile,
          'f-s-subtitle': $store.getters.is_mobile,
          'f-weight-medium': $store.getters.is_mobile
      }" v-text="get_i18n($store.getters.i_home_with_support, 'with_the')"></h2>
        <div class="images jc-center al-center" :class="{
          flex: !$store.getters.is_mobile,
          'text-al-center': $store.getters.is_mobile
      }">
            <img v-for="src in images_sort" :src="src" alt="">
        </div>
    </div>
</template>

<script>
import utils from "module/utils";

export default {
    name: "withSupport",
    methods: {
        get_i18n: utils.get_i18n
    },
    data() {
        return {
            images: [
                require('assets/home/img/with-support/hdf.png'),
                require('assets/home/img/with-support/logo-black.svg'),
            ]
        }
    },
    computed: {
        images_sort() {
            if (this.$store.getters.is_mobile) {
                return [this.images[1], this.images[0]]
            }
            return this.images
        }
    }
}
</script>

<style scoped lang="scss">
.with-support {
  padding: 48px 0 102px;

  h2 {
    width: 536px;
    margin-bottom: 48px;
  }

  .images {
    img {
      margin-right: 26px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  @media screen and (max-width: 520px) {
    padding: 16px 0;
    margin-top: 32px;
    h2 {
      width: auto;
      margin-bottom: 32px;
    }
    .images {
      img {
        max-width: 100%;
        height: auto;
        margin-right: 0;
      }
    }
  }
}
</style>