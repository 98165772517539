import {createRouter, createWebHistory} from 'vue-router'
import HomePage from "mnhome/pages/homePage.vue";




const routes = [
    {
        path: '/',
        name: "Home page",
        component: HomePage
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router