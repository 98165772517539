<template>
    <div
            class="image svg"
            :style="{
            width, height
        }"
    >
        <div v-show="(is_loading||sloading)&&showLoad"
             class="flex jc-center al-center img-loading"
             :class="{
                'bg-white': !sloading&&is_loading
             }" :style="{
                borderRadius: !sloading&&is_loading ? '12px' :''
             }"
             v-html="loading"></div>
        <slot :lazyPath="lazy_src">
            <img
                    :src="lazy_src"
                    :alt="alt"
                    @load="onLoad"
                    @error="OnError"
                    @loadstart="onStartLoad"
            >
        </slot>
    </div>
</template>

<script>
import env from "module/env";
import utils from "module/utils";

/* Prop type for lazy load image:
* WL - Window Loaded
* IOB - Intersection Observer
*
* */


export default {
    name: "lazyImage",
    props: {
        showLoad: Boolean,
        src: {
            required: true,
            type: String
        },
        alt: {
            type: String,
            default: 'monets.me image'
        },
        type: {
            default: "wl",
            type: String
        },
        width: [String, Number],
        height: [String, Number],
        sloading: Boolean
    },
    data() {
        return {
            is_loading: false,
            loading: env.icons.monets_coin,
            lazy_src: "",
            test: false,
            loaded: false
        }
    },
    methods: {
        onLoad() {
            console.log("LOADED", this.lazy_src)
            this.is_loading = false
        },
        onError() {
            this.is_loading = false
        },
        onStartLoad() {
            console.log("LOADING", this.lazy_src)
            this.is_loading = true
        }
    },
    async mounted() {
        switch (this.type.toLowerCase()) {
            case "wl":
                window.addEventListener("load", () => {
                    this.loaded = true
                    this.onStartLoad()
                    if (!this.test) this.lazy_src = this.src
                })
                break
            case "iob":
                utils.intersectionObserver(this.$el, (isv) => {
                    if (isv && !this.loaded) {
                        this.loaded = true
                        this.onStartLoad()
                        if (!this.test) this.lazy_src = this.src
                    }
                }, "300px")
                break
        }
    },
    watch: {
        test(v) {
            this.lazy_src = !v && this.loaded ? this.src : '';
        }
    }
}
</script>

<style scoped lang="scss">
.image {
  overflow: hidden;
  position: relative;

  .img-loading {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1;
    min-width: 300px;
    min-height: 300px;
    @media screen and (max-width: 520px) {
      min-width: 100%;
      min-height: 100px;
    }
  }

  img {
    width: inherit;
    height: inherit;
  }
}
</style>